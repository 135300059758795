import React from 'react';
import { TransactionDetailItem } from '../models/contracts';
import { PaymentsDetailsTable } from '../../../components/payments/PaymentsDetailsTable';
import { getTransactionDetailStatusAdapter } from '../../../adapters/transactionAdapter';
import { PAYMENT_METHODS } from '../../../constants/transactionConstants';

export type ContractPaidPaymentsProps = {
  transactionItems: Array<TransactionDetailItem>;
  duesPaids?: boolean;
};

const ContractPaidPayments: React.FC<ContractPaidPaymentsProps> = ({
  transactionItems,
  duesPaids = false,
}) => {
  const itemsMapped = transactionItems.map((item) => ({
    id: item.id,
    title: item.label,
    value: item.price,
    status: getTransactionDetailStatusAdapter(item.status),
    customDot: 'bg-success',
    href: `/contracts/${item.contractId}/quota/${item.id}`,
    due_month: item.due_month,
    payment_method: PAYMENT_METHODS[item.payment_method],
    has_proof_income: item.has_proof_income,
    status_aux: item.status,
  }));
  return (
    <PaymentsDetailsTable
      title="Detalle de cuotas pagadas"
      items={itemsMapped}
      dues_paids={duesPaids}
      show_due_months={true}
      currency=""
    />
  );
};

export default ContractPaidPayments;
