import { NavBarInternalRules } from '../models/navBarModel';
import { GT, SV } from '../constants/countryConstants';
import { ADMIN_ROLES } from '../constants/roleConstants';

const routesList: NavBarInternalRules[] = [
  {
    icon: 'fa fa-tachometer-alt',
    name: 'Dashboard',
    collapse: true,
    path: '/',
    state: 'propersDashboard',
    views: [
      {
        path: '/dashboard/visits',
        name: 'Resume Visitas',
        visible_roles: [],
      },
    ],
    visible_countries: [SV, GT],
    visible_roles: [
      ADMIN_ROLES.GENERAL_IT,
      ADMIN_ROLES.SUPPLY_RENT,
      ADMIN_ROLES.GENERAL_MANAGER,
      ADMIN_ROLES.MANAGER_RENT,
      ADMIN_ROLES.GENERAL_MARKETING,
      ADMIN_ROLES.SDR_RENT,
      ADMIN_ROLES.PROPER_RENT,
    ],
  },
  {
    icon: 'fa fa-house',
    path: '',
    name: 'Propiedades',
    collapse: true,
    state: 'propertiesCollapse',
    views: [
      {
        path: '/properties/view',
        name: 'Ver propiedades',
        visible_roles: [
          ADMIN_ROLES.GENERAL_MARKETING,
          ADMIN_ROLES.SDR_RENT,
          ADMIN_ROLES.PROPER_RENT,
        ],
      },
      {
        path: '/properties/create',
        name: 'Crear propiedad',
        visible_roles: [],
      },
      {
        path: '/residentials/',
        name: 'Residenciales',
        visible_roles: [],
      },
      {
        path: '/residentials/create',
        name: 'Crear residencial',
        visible_roles: [],
      },
    ],
    visible_countries: [SV, GT],
    visible_roles: [
      ADMIN_ROLES.GENERAL_IT,
      ADMIN_ROLES.GENERAL_MANAGER,
      ADMIN_ROLES.MANAGER_RENT,
      ADMIN_ROLES.SUPPLY_RENT,
      ADMIN_ROLES.CONTENT_RENT,
    ],
  },
  {
    icon: 'ni ni-camera-compact',
    name: 'Sesiones agendadas',
    collapse: false,
    path: '/properties/publish',
    state: 'schedulePhotoProperties',
    views: [],
    visible_countries: [SV, GT],
    visible_roles: [
      ADMIN_ROLES.GENERAL_IT,
      ADMIN_ROLES.GENERAL_MANAGER,
      ADMIN_ROLES.MANAGER_RENT,
      ADMIN_ROLES.SUPPLY_RENT,
      ADMIN_ROLES.PROPER_RENT,
      ADMIN_ROLES.CONTENT_RENT,
    ],
  },
  {
    icon: 'ni ni-folder-17',
    name: 'Contratos',
    collapse: true,
    path: '',
    state: 'contractCollapse',
    views: [
      {
        path: '/contract/view',
        name: 'Ver contratos',
        visible_roles: [],
      },
      {
        path: '/subscriptions/view/rental-managment',
        name: 'Rental managment',
        visible_roles: [],
      },
    ],
    visible_countries: [SV, GT],
    visible_roles: [
      ADMIN_ROLES.GENERAL_FINANCE,
      ADMIN_ROLES.GENERAL_IT,
      ADMIN_ROLES.GENERAL_MANAGER,
      ADMIN_ROLES.MANAGER_RENT,
      ADMIN_ROLES.SUPPLY_RENT,
      ADMIN_ROLES.PROPER_RENT,
    ],
  },
  {
    icon: 'ni ni-money-coins',
    name: 'Comisiones',
    collapse: true,
    path: '',
    state: 'commissionCollapse',
    views: [
      {
        path: '/commissions/calculator',
        name: '[Renta] Calculadora',
        visible_roles: [],
      },
      {
        path: '/commissions/pre-sale/scalculator',
        name: '[Presales] Calculadora',
        visible_roles: [],
      },
    ],
    visible_countries: [SV, GT],
    visible_roles: [
      ADMIN_ROLES.GENERAL_FINANCE,
      ADMIN_ROLES.GENERAL_IT,
      ADMIN_ROLES.GENERAL_MANAGER,
      ADMIN_ROLES.MANAGER_RENT,
      ADMIN_ROLES.SUPPLY_RENT,
      ADMIN_ROLES.PROPER_RENT,
    ],
  },
  // {
  //   icon: 'fa fa-person',
  //   path: '',
  //   name: 'Inquilinos',
  //   state: 'tenantCollapse',
  //   collapse: true,
  //   views: [
  //     {
  //       path: '/tenant/view',
  //       name: 'Ver inquilinos',
  //       visible_roles: [
  //         ADMIN_ROLES.GENERAL_MARKETING,
  //         ADMIN_ROLES.GENERAL_FINANCE,
  //       ],
  //     },
  //     {
  //       path: '/tenant/create',
  //       name: 'Crear inquilino',
  //       visible_roles: [],
  //     },
  //   ],
  //   visible_countries: [SV, GT],
  //   visible_roles: [
  //     ADMIN_ROLES.GENERAL_IT,
  //     ADMIN_ROLES.GENERAL_MANAGER,
  //     ADMIN_ROLES.MANAGER_RENT,
  //     ADMIN_ROLES.SDR_RENT,
  //   ],
  // },
  {
    icon: 'fa fa-people-roof',
    path: '',
    name: 'Usuarios',
    state: 'landlordCollapse',
    collapse: true,
    views: [
      {
        path: '/customer/view',
        name: 'Ver usuarios',
        visible_roles: [
          ADMIN_ROLES.GENERAL_MARKETING,
          ADMIN_ROLES.GENERAL_FINANCE,
        ],
      },
      {
        path: '/customer/create',
        name: 'Crear usuarios',
        visible_roles: [],
      },
    ],
    visible_countries: [SV, GT],
    visible_roles: [
      ADMIN_ROLES.PROPER_SALES,
      ADMIN_ROLES.MANAGER_SALES,
      ADMIN_ROLES.GENERAL_IT,
      ADMIN_ROLES.GENERAL_MANAGER,
      ADMIN_ROLES.MANAGER_RENT,
      ADMIN_ROLES.SUPPLY_SALES,
      ADMIN_ROLES.SDR_SALES,
      ADMIN_ROLES.SDR_RENT,
      ADMIN_ROLES.SUPPLY_RENT,
      ADMIN_ROLES.CONTENT_RENT,
    ],
  },
  {
    icon: 'ni ni-single-02',
    path: '',
    name: 'Guests',
    state: 'guestCollapse',
    collapse: true,
    views: [
      {
        path: '/guest/offers',
        name: 'Ofertas',
        visible_roles: [ADMIN_ROLES.SDR_RENT, ADMIN_ROLES.PROPER_RENT],
      },
      {
        path: '/guest/visits',
        name: 'Visitas',
        visible_roles: [
          ADMIN_ROLES.SUPPLY_RENT,
          ADMIN_ROLES.SDR_RENT,
          ADMIN_ROLES.PROPER_RENT,
        ],
      },
      {
        path: '/guest/properties',
        name: 'Propiedades',
        visible_roles: [ADMIN_ROLES.SUPPLY_RENT],
      },
    ],
    visible_countries: [SV, GT],
    visible_roles: [
      ADMIN_ROLES.GENERAL_MARKETING,
      ADMIN_ROLES.GENERAL_IT,
      ADMIN_ROLES.GENERAL_MANAGER,
      ADMIN_ROLES.MANAGER_RENT,
    ],
  },
  {
    icon: 'fa fa-duotone fa-money-bill',
    path: '',
    name: 'Pago',
    state: 'contractPaymentsCollapse',
    collapse: true,
    views: [
      {
        path: '/contracts/payment-status',
        name: 'Pagos de alquiler',
        visible_roles: [],
      },
      {
        path: '/transaction-sales',
        name: 'Pagos de vivienda usada',
        visible_roles: [],
      },
    ],
    visible_countries: [SV, GT],
    visible_roles: [
      ADMIN_ROLES.GENERAL_FINANCE,
      ADMIN_ROLES.GENERAL_IT,
      ADMIN_ROLES.GENERAL_MANAGER,
      ADMIN_ROLES.MANAGER_RENT,
    ],
  },
  {
    icon: 'fa fa-duotone fa-building',
    path: '',
    name: 'Inversiones',
    state: 'investorCollapse',
    collapse: true,
    views: [
      {
        path: '/investors/projects/create',
        name: 'Agregar proyecto',
        visible_roles: [],
      },
      {
        path: '/investors/projects/',
        name: 'Proyectos',
        visible_roles: [
          ADMIN_ROLES.GENERAL_MARKETING,
          ADMIN_ROLES.SDR_SALES,
          ADMIN_ROLES.PROPER_SALES,
        ],
      },
      {
        path: '/investors/payments/',
        name: 'Pagos',
        visible_roles: [ADMIN_ROLES.GENERAL_FINANCE, ADMIN_ROLES.PROPER_SALES],
      },
      {
        path: '/investors/guest/visits',
        name: 'Asesorías',
        visible_roles: [
          ADMIN_ROLES.GENERAL_MARKETING,
          ADMIN_ROLES.SDR_SALES,
          ADMIN_ROLES.PROPER_SALES,
        ],
      },
      {
        path: '/investors/guest/pre-booking',
        name: 'Pre-reservas',
        visible_roles: [
          ADMIN_ROLES.GENERAL_MARKETING,
          ADMIN_ROLES.SDR_SALES,
          ADMIN_ROLES.PROPER_SALES,
        ],
      },
      {
        path: '/investors/configs/config-sort',
        name: 'Ordenamiento de proyectos',
        visible_roles: [ADMIN_ROLES.GENERAL_FINANCE, ADMIN_ROLES.PROPER_SALES],
      },
    ],
    visible_countries: [SV, GT],
    visible_roles: [
      ADMIN_ROLES.MANAGER_SALES,
      ADMIN_ROLES.GENERAL_IT,
      ADMIN_ROLES.GENERAL_MANAGER,
      ADMIN_ROLES.SUPPLY_SALES,
    ],
  },
  {
    icon: 'fa fa-duotone fa-money-bill',
    path: '',
    name: 'Contabilidad',
    state: 'accountBillingCollapse',
    collapse: true,
    views: [
      {
        path: '/accounting/proof-income',
        name: 'Comprobante de ingreso',
        visible_roles: [ADMIN_ROLES.MANAGER_SALES, ADMIN_ROLES.SUPPLY_SALES],
      },
      {
        path: '/accounting/proof-outcome/create',
        name: 'Crear comprobante de egreso',
        visible_roles: [],
      },
      {
        path: '/accounting/proof-outcome/',
        name: 'Comprobantes de egreso',
        visible_roles: [ADMIN_ROLES.MANAGER_SALES, ADMIN_ROLES.SUPPLY_SALES],
      },
    ],
    visible_countries: [SV, GT],
    visible_roles: [
      ADMIN_ROLES.GENERAL_IT,
      ADMIN_ROLES.GENERAL_MANAGER,
      ADMIN_ROLES.GENERAL_FINANCE,
    ],
  },
  {
    icon: 'ni ni-settings',
    path: '',
    name: 'Configuraciones',
    collapse: true,
    state: 'configCollapse',
    views: [
      {
        path: '/settings/home-slider',
        name: 'Carrusell Home',
        visible_roles: [],
      },
      {
        path: '/settings/propi-estimate',
        name: 'Propi estimate',
        visible_roles: [ADMIN_ROLES.SUPPLY_RENT],
      },
    ],
    visible_countries: [SV, GT],
    visible_roles: [ADMIN_ROLES.GENERAL_IT],
  },
  {
    icon: 'ni ni-box-2',
    path: '',
    name: 'Desestimiento',
    collapse: true,
    state: 'withdrawalCollapse',
    views: [
      {
        path: '/withdrawal/pre-sale/create',
        name: 'Crear desistimiento para pre-venta',
        visible_roles: [ADMIN_ROLES.PROPER_SALES],
      },
      {
        path: '/withdrawal',
        name: 'Lista de desistimiento',
        visible_roles: [],
      },
    ],
    visible_countries: [SV, GT],
    visible_roles: [
      ADMIN_ROLES.GENERAL_IT,
      ADMIN_ROLES.GENERAL_MANAGER,
      ADMIN_ROLES.GENERAL_FINANCE,
    ],
  },
];

const useNavBar = () => {
  /**
   * This service returns nav bar filtered by roles and countries,
   * The way to analize ther roles and permission is:
   * 1- check if user country has visibility to menu
   * 2- result from point 1 muste be checked by user roles has visibility to main or detail category menu
   * @param country
   * @param assigned_roles
   * @returns
   */
  const getNavBar = (country: string, assigned_roles: string[] = []) => {
    const routesFilteredByCountry: NavBarInternalRules[] = routesList.filter(
      (mainMenuItem) => mainMenuItem.visible_countries?.includes(country),
    );
    const routesFilteredByRoles: NavBarInternalRules[] = [];
    routesFilteredByCountry.forEach((item) => {
      // check main item role
      const hasMainPermission = item.visible_roles?.some((menuRole) => {
        return assigned_roles.includes(menuRole);
      });
      if (hasMainPermission) {
        routesFilteredByRoles.push(item);
      } else {
        // check each child item role
        const filteredSubMenu = item.views.filter((subItem) => {
          const hasSubPermission = subItem.visible_roles?.some(
            (subMenuRole) => {
              return assigned_roles.includes(subMenuRole);
            },
          );
          if (hasSubPermission) {
            return subItem;
          }
        });
        if (filteredSubMenu.length > 0) {
          item.views = filteredSubMenu;
          routesFilteredByRoles.push(item);
        }
      }
    });
    return routesFilteredByRoles && routesFilteredByRoles.length
      ? routesFilteredByRoles
      : [];
  };

  return {
    getNavBar,
  };
};

export default useNavBar;
