import { getTransactionDetailStatusAdapter } from '../../../adapters/transactionAdapter';
import { PaymentsDetailsTable } from '../../../components/payments/PaymentsDetailsTable';
import { TransactionPreSaleDto } from '../models/transactionSaleModel';

interface Props {
  transactionSaleId: string;
  transactionItems: TransactionPreSaleDto[];
  label: string;
  currency: string;
}

const TransactionSaleInfoPayments: React.FC<Props> = ({
  transactionSaleId,
  transactionItems,
  label,
  currency = 'USD',
}) => {
  const itemsMapped = transactionItems.map((item) => ({
    id: item.id,
    title: item.label,
    value: item.price,
    status: getTransactionDetailStatusAdapter(item.status),
    customDot: item.status === 'paid' ? 'bg-success' : '',
    href: `/transaction-sales/${transactionSaleId}/quota/${item.id}`,
    status_aux: item.status,
  }));
  return (
    <PaymentsDetailsTable
      title={label}
      items={itemsMapped}
      currency={currency}
    />
  );
};

export default TransactionSaleInfoPayments;
