import { useState } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { getLandlordByMailEndpoint } from '../../services/landlordService';
import useAuth from '../../hooks/useAuth';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';

interface Landlord {
  landlord_id: string;
  profile_id: string;
  name: string;
  phone: string;
  email: string;
  avatar: string;
  is_account_verified: boolean;
}

interface Props {
  header: string;
  onLandlordfound: (landlordId: string, landlord: Landlord) => void;
  error_form_message?: string;
}

const LandlordSearchInput = ({
  header,
  onLandlordfound,
  error_form_message,
}: Props) => {
  const { callEndpoint } = useCallApiAndLoad();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const [errorAlert, setErrorAlert] = useState<string>('');
  const [landlordEmail, setLandlordEmail] = useState<string>('');
  const [landlord, setLandlord] = useState<Landlord>();

  const handleSearchLandlord = async (landlordEmailForSearch: string) => {
    setErrorAlert('');
    if (!landlordEmailForSearch) return;
    const { status, data } = await callEndpoint(
      getLandlordByMailEndpoint(landlordEmailForSearch, token),
    );
    if (status === 200) {
      const landlordResponse = fetchResponseAdapter(data);
      const landlordInfo: Landlord = landlordResponse.data;
      setLandlord(landlordResponse.data);
      onLandlordfound(landlordInfo.landlord_id, landlordInfo);
    } else {
      setErrorAlert('El propietario/inversionista no fue encontrado.');
    }
  };
  return (
    <>
      <Col md="12">
        <FormGroup>
          <label className="form-control-label" htmlFor="plan_id">
            {header}
          </label>
          <InputGroup className="mb-2">
            <Input
              onChange={({ target }) => setLandlordEmail(target.value || '')}
              className={errorAlert && 'is-invalid'}
              placeholder="Correo de propietario"
              type="text"
            />
            <InputGroupAddon addonType="append">
              <Button
                onClick={() => handleSearchLandlord(landlordEmail)}
                color="primary"
                outline
              >
                {' '}
                Buscar
              </Button>
              <Button
                onClick={() => {
                  setErrorAlert('');
                  setLandlord(undefined);
                  setLandlordEmail('');
                }}
                color="default"
              >
                {' '}
                Limpiar
              </Button>
            </InputGroupAddon>
          </InputGroup>
          <h5 className="text-danger">{errorAlert}</h5>
          {landlord && (
            <h5>
              Asignaras a: {landlord.name} | Teléfono: {landlord.phone}
            </h5>
          )}
          {error_form_message && (
            <h5 className="text-danger">{error_form_message}</h5>
          )}
        </FormGroup>
      </Col>
    </>
  );
};

export default LandlordSearchInput;
