import { useNavigate } from 'react-router-dom';
import { Badge, Button, Card, CardBody, ListGroup, Table } from 'reactstrap';
import InvestorDownPaymentQuotasModal from '../../pages/investors/components/InvestorDownPaymentQuotasModal';
import useModalState from '../../hooks/useModalState';
import { useState } from 'react';
import QuotaFormModal from '../../pages/quotas/QuotaFormModal';
import { QuotaDetailForm } from '../../pages/quotas/models/adminQuotas';
import { toNumber } from '../../adapters/numberAdapter';
import { QUOTA_TYPES } from '../../constants/quotaConstants';

interface DisplayTransactionRow {
  id: string;
  title: string;
  value: string;
  status: string;
  customDot?: string;
  href?: string;
  allow_divide_quota?: boolean;
  due_month?: string;
  payment_method?: string;
  has_proof_income?: boolean;
  max_quotas?: number;
  finance_type?: string;
  allow_update_quota?: boolean;
  status_aux: string;
}

interface Props {
  transactionId?: string;
  title: string;
  items: DisplayTransactionRow[];
  currency: string;
  type?: QUOTA_TYPES;
  dues_paids?: boolean;
  show_due_months?: boolean;
  allow_update_quota?: boolean;
  allow_create_quota?: boolean;
}

export const PaymentsDetailsTable = ({
  transactionId,
  items = [],
  title = '',
  type = 'rent',
  currency = 'USD',
  dues_paids = false,
  show_due_months = false,
  allow_update_quota = false,
  allow_create_quota = false,
}: Props) => {
  const [isOpenModal, toggleModal] = useModalState();
  const [quotaOpenModal, quotaToggleModal] = useModalState();
  const [transactionDetailId, setTransactionDetailId] = useState('');
  const [maxQuotas, setMaxQuotas] = useState(0);
  const [headerModal, setHeaderModal] = useState('');
  const [financeText, setFinanceText] = useState('');
  const [financeType, setFinanceType] = useState('');
  const [quota, setQuota] = useState<QuotaDetailForm>({});
  const [isCreateMode, setIsCreateMode] = useState(false);

  const navigate = useNavigate();

  return (
    <Card>
      <CardBody>
        <ListGroup className="list my--3" flush>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h6 className="heading-small text-muted mt-4 mb-4">{title}</h6>
            {allow_create_quota && (
              <Button
                type="button"
                size="sm"
                color="info"
                onClick={() => {
                  setQuota({
                    currency: currency,
                    related_id: transactionId,
                    type: type,
                  });
                  setIsCreateMode(true);
                  quotaToggleModal();
                }}
              >
                <i className="ni ni-fat-add pt-1" /> Agregar cuota
              </Button>
            )}
          </div>

          <div
            style={{
              height: '300px',
              overflow: 'scroll',
            }}
          >
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Concepto</th>
                  {show_due_months && <th scope="col">Mes cuota</th>}
                  <th scope="col">Estado</th>
                  {dues_paids && <th scope="col">Forma de pago</th>}
                  {dues_paids && <th scope="col">Comprobante de ingreso</th>}
                  <th scope="col">Monto</th>
                </tr>
              </thead>
              <tbody>
                {items.length > 0 &&
                  items.map((item) => (
                    <tr key={item.title}>
                      <td>{item.title}</td>
                      {show_due_months && <td>{item.due_month}</td>}
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i
                            className={`${
                              item.customDot ? item.customDot : 'bg-warning'
                            }`}
                          />
                          {item.status}
                        </Badge>
                      </td>
                      {dues_paids && <td>{item.payment_method}</td>}
                      {dues_paids && (
                        <td>
                          {item.has_proof_income ? (
                            <Badge className="badge-sm" color="success">
                              Generado
                            </Badge>
                          ) : (
                            <Badge className="badge-sm" color="warning">
                              Pendiente
                            </Badge>
                          )}
                        </td>
                      )}
                      <td>
                        {item.value}
                        <Button
                          onClick={() =>
                            item.href ? navigate(`${item.href}`) : false
                          }
                          color=""
                          size="sm"
                          type="button"
                        >
                          <i className="ni ni-bold-right pt-1" />
                        </Button>
                        {item.allow_divide_quota && (
                          <Button
                            color="primary"
                            size="sm"
                            type="button"
                            onClick={() => {
                              setTransactionDetailId(item.id);
                              toggleModal();
                              setMaxQuotas(item.max_quotas ?? -1);
                              setHeaderModal(
                                `Dividir ${item.title.toLocaleLowerCase()} en cuotas`,
                              );
                              setFinanceText(item.title.toLocaleLowerCase());
                              setFinanceType(item.finance_type ?? '');
                            }}
                          >
                            <i className="ni ni-money-coins pt-1" /> Dividir{' '}
                            {item.title.toLocaleLowerCase()} en cuotas
                          </Button>
                        )}
                        {!dues_paids && allow_update_quota && (
                          <Button
                            color="warning"
                            size="sm"
                            type="button"
                            onClick={() => {
                              setTransactionDetailId(item.id);
                              setQuota({
                                price: toNumber(item.value),
                                status: item.status_aux,
                              });
                              setIsCreateMode(false);
                              quotaToggleModal();
                            }}
                          >
                            <i className="fa fa-pencil pt-1" />
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </ListGroup>
      </CardBody>
      <InvestorDownPaymentQuotasModal
        isOpen={isOpenModal}
        onToggle={toggleModal}
        headerText={headerModal}
        transactionPreSaleId={transactionId}
        transactionDetailId={transactionDetailId}
        max_quotas={maxQuotas}
        finance_text={financeText}
        finance_type={financeType}
      />
      <QuotaFormModal
        headerText={isCreateMode ? 'Crear cuota' : 'Actualizar cuota'}
        isOpen={quotaOpenModal}
        marketplace_type={type}
        onToggle={quotaToggleModal}
        quotaDetail={quota}
        related_id={transactionId ?? ''}
        is_create_mode={isCreateMode}
        quota_id={transactionDetailId}
        currency={currency}
      />
    </Card>
  );
};
