import { Badge } from 'reactstrap';
import { ProjectsConfig } from '../models/projectModel';
import { isProjectVisibleInMarketplace } from '../helpers/investorHelpers';
import { useState } from 'react';

interface Props {
  project: ProjectsConfig;
  isDragging: boolean;
  handleChange: (projectId: string, change: boolean) => void;
}

const ProjectCard = ({ project, isDragging, handleChange }: Props) => {
  const [isFeature, setIsFeature] = useState<boolean>(project.isFeature);

  const handleChangeIsFeatured = (projectId: string, change: boolean) => {
    setIsFeature(change);
    handleChange(projectId, change);
  };

  return (
    <div
      className={`d-flex align-items-center ${isDragging ? 'shadow' : ''}`}
      style={{
        borderRadius: '4px',
        border: '1px solid #e0e0e0',
        background: isFeature ? '#f0f7ff' : '#ffffff',
        padding: '8px 12px',
        height: '100px',
        transition: 'all 0.2s ease',
      }}
    >
      {/* Project Image - Small Thumbnail */}
      {project.code && (
        <div
          className="me-6"
          style={{ width: '50px', height: '50px', flexShrink: 0 }}
        >
          <img
            src={project.cover}
            alt={project.name}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '3px',
            }}
          />
        </div>
      )}

      {/* Project Info */}
      <div className="flex-grow-1 ml-2">
        <div className="d-flex justify-content-between align-items-center">
          <h6
            className="mb-0 text-truncate"
            style={{ maxWidth: '150px', fontSize: '14px' }}
          >
            {project.name}
          </h6>
          <small className="text-muted" style={{ fontSize: '12px' }}>
            CÓDIGO: {project.code}
          </small>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-1">
          <Badge
            color={
              isProjectVisibleInMarketplace(project.status)
                ? 'success'
                : 'danger'
            }
          >
            <span style={{ fontSize: '10px', padding: '2px 6px' }}>
              {isProjectVisibleInMarketplace(project.status)
                ? 'Visible en marketplace'
                : 'Oculto en marketplace'}
            </span>
          </Badge>

          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              checked={isFeature}
              onChange={(e) =>
                handleChangeIsFeatured(project.id, e.target.checked)
              }
              style={{ transform: 'scale(0.8)' }}
            />
            <small className="ms-1" style={{ fontSize: '11px' }}>
              {isFeature ? 'DESTACADA' : 'NO DESTACADA'}
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
