import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import AdminPage from '../../components/admin/AdminPage';
import LoadingCard from '../../components/LoadingCard';
import useAdminEntity from '../../hooks/useAdminEntity';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import InvestFuturePayments from './components/InvestFuturePayments';
import InvestorTransactionDetailCard from './components/InvestorTransactionDetailCard';
import InvestPaidPayments from './components/InvestPaidPayments';
import { InvestorPaymentDetailData } from './models/investors';
import {
  getInvestorPaymentsDetailEndpoint,
  unselectTransactionPreSalesEndpoint,
} from './services/investorService';
import useModalState from '../../hooks/useModalState';
import CancelEntityInvestorModal from '../../components/landlordInvestors/CancelEntityInvestorModal';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import InvestorTransactionPreSaleBankingModal from './InvestorTransactionPreSaleBankingModal';
import CommissionDetailModal from '../commissions/CommissionDetailModal';
import InvestorJoinTransactionPreSaleCard from './components/InvestorJointTransactionPreSaleCard';
import InvestorAddJointTransactionPreSaleButton from './components/InvestorAddJointTransactionPreSaleButton';

const InvestorPaymentDetail = () => {
  const { id: transactionId = '' } = useParams();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [errorAlert, setErrorAlert] = useState(null);
  const pageName = 'Detalle de Reserva';
  const pageParentName = 'Pago de Reserva';
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };
  const navigate = useNavigate();

  const getTransactionDetail = useCallback(
    () => getInvestorPaymentsDetailEndpoint(transactionId, token),
    [transactionId, token],
  );
  const disableBooking = useCallback(async (transactionId: string) => {
    const { status, data } = await callEndpoint(
      unselectTransactionPreSalesEndpoint(transactionId, token),
    );
    if (status === 200) {
      toggleDisableBookingModal();
      navigate('/investors/payments/');
    } else {
      const response = fetchResponseAdapter(data);
      setErrorAlert(response.message);
      toggleDisableBookingModal();
    }
  }, []);

  const [transaction] = useAdminEntity<InvestorPaymentDetailData>(
    getTransactionDetail,
    setErrorAlert,
  );
  const [showDisableBookingModal, toggleDisableBookingModal] = useModalState();

  if (!transaction || isLoading)
    return <LoadingCard name={pageName} parent_name={pageParentName} />;

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
    >
      <Row>
        <Col lg="4">
          <InvestorTransactionDetailCard
            transactionDetails={transaction.transaction}
            footer={
              <>
                {transaction.transaction_detail_paid.length <= 0 && (
                  <Button
                    color={'danger'}
                    onClick={() => toggleDisableBookingModal()}
                  >
                    Anular reserva
                  </Button>
                )}
                <InvestorTransactionPreSaleBankingModal
                  transactionPreSaleId={transaction.transaction.id}
                  transactionPreSale={{
                    is_cash_payment: transaction.transaction.is_cash_payment,
                    is_joint: transaction.transaction.is_joint,
                  }}
                />
                <CommissionDetailModal
                  commission_id={transaction.transaction?.commission_id || null}
                  transactionPresale={transaction}
                />
                <InvestorAddJointTransactionPreSaleButton
                  transaction_pre_sale_id={transaction.transaction.id}
                />
              </>
            }
          />

          <InvestorJoinTransactionPreSaleCard
            joints={transaction.transaction.joints}
          />
        </Col>
        <Col lg="8">
          <Row>
            <Col lg="12">
              {/* Invest Future payments */}
              <InvestFuturePayments
                transactionId={transactionId}
                items={transaction.transaction_detail}
                currency={transaction.transaction.currency}
                type="pre_sale"
              />
            </Col>
            <Col lg="12">
              {/* Contract Paid payments */}
              <InvestPaidPayments
                transactionId={transactionId}
                items={transaction.transaction_detail_paid}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <CancelEntityInvestorModal
        guestId={transactionId}
        isOpen={showDisableBookingModal}
        onToggle={toggleDisableBookingModal}
        onConfirmation={disableBooking}
        bodyText={
          'Al anular la reserva se vuelve a colocar la unidad como disponible en el marketplace.'
        }
        confirmationText={'Entiendo, anular'}
        abortText={'No, seguir revisando...'}
        titleText={'Anular reserva'}
      />
    </AdminPage>
  );
};

export default InvestorPaymentDetail;
