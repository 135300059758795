import axios from 'axios';
import { CreateJointPreSale } from '../models/jointInvestor';
const loadAbort = () => {
  return new AbortController();
};

export const addJointPreSalesEndpoint = (
  payload: CreateJointPreSale,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'POST',
        url: `${urlService}/ts/v1/admin/joints`,
        data: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const deleteJointPreSalesEndpoint = (id: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'DELETE',
        url: `${urlService}/ts/v1/admin/joints/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
