import { QUOTA_TYPES } from '../../constants/quotaConstants';
import { QuotaDetailForm } from './models/adminQuotas';
import FormModal from '../../components/modals/FormModal';
import QuotaForm from './QuotaForm';
import useAdminQuotas from './hooks/useAdminQuotas';
import { Option } from '../../models/formModel';
import { useEffect, useState } from 'react';
import { listToSelectOptionAdapter } from '../../adapters/listAdapter';

interface Props {
  headerText: string;
  isOpen: boolean;
  onToggle: () => void;
  quotaDetail: QuotaDetailForm;
  marketplace_type: QUOTA_TYPES;
  related_id: string;
  belongs_quota_id?: string | null;
  is_create_mode: boolean;
  quota_id: string | null;
  currency: string;
}

const QuotaFormModal = ({
  headerText,
  isOpen,
  marketplace_type,
  onToggle,
  quotaDetail,
  related_id,
  quota_id,
  currency,
  is_create_mode = false,
}: Props) => {
  const { doAddQuota, doUpdateQuota, doRetrieveValidFinancesPerMarketplace } =
    useAdminQuotas();

  const [financesPerMarketplace, setFinancesPerMarketplace] = useState<
    Option[]
  >([]);

  useEffect(() => {
    loadFinancesMarketplaceType().then().catch(console.error);
  }, [is_create_mode]);

  const loadFinancesMarketplaceType = async () => {
    if (is_create_mode) {
      //call
      const result = await doRetrieveValidFinancesPerMarketplace(
        marketplace_type,
      );

      setFinancesPerMarketplace(
        listToSelectOptionAdapter(result, 'id', 'name', false),
      );
    }
  };

  const handleCreateOrUpdate = async (form: QuotaDetailForm) => {
    if (quota_id) {
      //update
      //must be update just status or price
      doUpdateQuota(quota_id, {
        status: form.status,
        price: form.price,
      });
    } else {
      //create
      doAddQuota({
        currency: form.currency ?? '',
        due: form.due ?? '00',
        due_date: form.due_date ?? '',
        finance_id: form.finance_id ?? '',
        price: form.price ?? 0,
        related_id: form.related_id ?? '',
        type: form.type ?? 'rent',
        month: form.due_date?.split('-')[1] ?? '',
        year: form.due_date?.split('-')[0] ?? '',
        belongs_quota_id: form.belongs_quota_id ?? null,
      });
    }
  };

  const quotaForm = (
    <QuotaForm
      currency={currency}
      is_create_mode={is_create_mode}
      marketplace_type={marketplace_type}
      quota={quotaDetail}
      related_id={related_id}
      handleCreateOrUpdate={handleCreateOrUpdate}
      financesPerMarketplace={financesPerMarketplace}
    />
  );

  return (
    <FormModal
      size="lg"
      children={quotaForm}
      headerText={headerText}
      isOpen={isOpen}
      onConfirmation={() => {}}
      onReset={() => {}}
      onToggle={onToggle}
      withFooterActions={false}
    />
  );
};

export default QuotaFormModal;
