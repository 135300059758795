import React, { useCallback, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  ListGroup,
  ListGroupItem,
  Modal,
  Row,
  UncontrolledCarousel,
} from 'reactstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

export type Props = {
  gallery: Array<any>;
  description: string;
  onDelete?: Function;
  onDeleteAll?: Function;
  onSync?: Function;
  onDrag?: (images: string[]) => void;
};

const AdminResourceGallery: React.FC<Props> = ({
  gallery,
  description,
  onDelete,
  onDeleteAll,
  onDrag,
  onSync,
}) => {
  const initialGalleryForDisplay = gallery.filter(
    (image) =>
      !image.toString().includes('null') &&
      !image.toString().includes('undefined'),
  );
  const [galleryForDisplay, setGalleryForDisplay] = useState<string[]>(
    initialGalleryForDisplay,
  );

  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<string>('');

  const previewModal = (image: string) => {
    setCurrentImage(image);
    setShowModal((state) => !state);
  };

  const onDragEnd = useCallback(
    (event: any) => {
      const srcIndex = event.source.index;
      const dstIndex = event.destination.index;

      const newGalleryForDisplay = [...galleryForDisplay];
      newGalleryForDisplay.splice(
        dstIndex,
        0,
        newGalleryForDisplay.splice(srcIndex, 1)[0],
      );

      setGalleryForDisplay(newGalleryForDisplay);

      if (onDrag) onDrag(newGalleryForDisplay);
    },
    [onDrag, galleryForDisplay],
  );

  let bodyList;

  if (onDrag === undefined) {
    bodyList = (
      <ListGroup className="list my--3" flush>
        {galleryForDisplay &&
          galleryForDisplay.length > 0 &&
          galleryForDisplay.map((image) => (
            <ListGroupItem key={image} className="px-0">
              <Row className="align-items-center">
                <Col className="col-auto">
                  <img
                    onClick={() => previewModal(image || '')}
                    className="rounded"
                    alt="..."
                    width={175}
                    height={100}
                    src={image || ''}
                  />
                </Col>
                <div className="col ml--2"></div>
                <Col className="col-auto">
                  <Button
                    className="btn-icon"
                    color="secondary"
                    size="sm"
                    type="button"
                    onClick={() => previewModal(image || '')}
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-image" />
                    </span>
                    <span className="btn-inner--text">Ver</span>
                  </Button>
                  {onDelete && (
                    <Button
                      className="btn-icon"
                      color="danger"
                      size="sm"
                      onClick={() => onDelete(image)}
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-image" />
                      </span>
                      <span className="btn-inner--text">Eliminar</span>
                    </Button>
                  )}
                </Col>
              </Row>
            </ListGroupItem>
          ))}
      </ListGroup>
    );
  } else {
    bodyList = (
      <DragDropContext onDragEnd={onDragEnd}>
        <ListGroup className="list my--3" flush>
          <Droppable droppableId={description}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {provided.placeholder}
                {galleryForDisplay &&
                  galleryForDisplay.length > 0 &&
                  galleryForDisplay.map((image, idx) => (
                    <Draggable key={image} draggableId={image} index={idx}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <ListGroupItem>
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <i
                                  className="fa fa-grip-lines"
                                  {...provided.dragHandleProps}
                                />
                              </Col>
                              <Col className="col-auto">
                                <img
                                  // onClick={() => toggleModal(image || '')}
                                  className="rounded"
                                  alt="..."
                                  width={175}
                                  height={100}
                                  src={image || ''}
                                />
                              </Col>
                              <div className="col ml--2"></div>
                              <Col className="col-auto">
                                <Button
                                  className="btn-icon"
                                  color="secondary"
                                  size="sm"
                                  href={image}
                                  target="_blank"
                                >
                                  <span className="btn-inner--icon">
                                    <i className="ni ni-image" />
                                  </span>
                                  <span className="btn-inner--text">Ver</span>
                                </Button>
                                {onDelete && (
                                  <Button
                                    className="btn-icon"
                                    color="danger"
                                    size="sm"
                                    onClick={() => onDelete(image)}
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="ni ni-image" />
                                    </span>
                                    <span className="btn-inner--text">
                                      Eliminar
                                    </span>
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          </ListGroupItem>
                        </div>
                      )}
                    </Draggable>
                  ))}
              </div>
            )}
          </Droppable>
        </ListGroup>
      </DragDropContext>
    );
  }

  return (
    <>
      <Card>
        <CardHeader>
          <h4>{description}</h4>
        </CardHeader>
        <CardBody>
          {(!galleryForDisplay || galleryForDisplay.length <= 0) && (
            <p>No hay elementos que mostrar.</p>
          )}

          {bodyList}
        </CardBody>
        {onDeleteAll && galleryForDisplay.length > 0 && (
          <CardFooter>
            <Button
              className="btn-icon"
              color="danger"
              size="sm"
              onClick={() => {
                onDeleteAll(galleryForDisplay);
              }}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-image" />
              </span>
              <span className="btn-inner--text">Eliminar todas</span>
            </Button>
            {onSync && (
              <Button
                className="btn-icon"
                color="info"
                size="sm"
                onClick={() => {
                  onSync();
                }}
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-cloud-download-95" />
                </span>
                <span className="btn-inner--text">Sincronización manual</span>
              </Button>
            )}
          </CardFooter>
        )}
        {onSync && galleryForDisplay.length === 0 && (
          <CardFooter>
            <Button
              className="btn-icon"
              color="info"
              size="sm"
              onClick={() => {
                onSync();
              }}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-cloud-download-95" />
              </span>
              <span className="btn-inner--text">Sincronización manual</span>
            </Button>
          </CardFooter>
        )}
      </Card>
      <Modal
        size="lg"
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={() => previewModal('')}
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => previewModal('')}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <UncontrolledCarousel
            controls={false}
            indicators={false}
            items={[
              {
                src: currentImage,
                altText: '',
                caption: '',
                header: '',
              },
            ]}
          />
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </>
  );
};

export default AdminResourceGallery;
