import axios from 'axios';
import { TransactionPreSaleForm } from '../models/investors';
const loadAbort = () => {
  return new AbortController();
};

export const updateTransactionPreSaleEndpoint = (
  id: string,
  payload: TransactionPreSaleForm,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'PUT',
        url: `${urlService}/ts/v1/admin/transaction-pre-sales/${id}`,
        data: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
