import axios from 'axios';

const loadAbort = () => {
  return new AbortController();
};

export const projectListByPositionEndpoint = (token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/pre-sales/v1/admin/projects/by-position`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
