import axios from 'axios';
import {
  CreatePropertyFormApi,
  CreatePropertyFormForApi,
  CreateVisitFormApi,
  PropertyAdditionalInfoForm,
  PropertyLocationAddressForm,
  PropertyUpdateIsVerifiedApi,
  PublishPropertyForm,
  RescheduleFormApi,
  UpdatePropertyAmenitiesApiPayload,
  UpdatePropertyRentInfoForm,
  UpdatePropertySalesPricesForm,
  UploadLinksForm,
} from '../models/propertyModel';

const loadAbort = () => {
  return new AbortController();
};

export const getCharacteristicsEndpoint = (token: string = '') => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/rs/v1/characteristics?is_special_feature=true`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getTypePropertiesEndpoint = (token: string = '') => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/rs/v1/type-properties`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getAmenitiesEndpoint = (token: string = '') => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/rs/v1/amenities`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getPlansEndpoint = (token: string = '') => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/rs/v1/plans`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

//TODO move this to root services
export const getTenantByMailEndpoint = (email: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/user/v1/admin/tenant/search-mail`,
        params: { email },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getPropertiesEndpoint = (query: string = '', token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/rs/v1/admin/properties${query}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getPhotoSessionsPropertiesEndpoint = (
  query: string = '',
  token: string = '',
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/rs/v1/admin/properties${query}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getPropertyToEditEndpoint = (
  propertyId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/rs/v1/admin/properties/${propertyId}/edit`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const createPropertyEndpoint = (
  form: CreatePropertyFormForApi,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/rs/v1/admin/properties`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updatePropertyEndpoint = (
  form:
    | CreatePropertyFormForApi
    | PropertyAdditionalInfoForm
    | PropertyLocationAddressForm
    | UpdatePropertyRentInfoForm
    | UpdatePropertyAmenitiesApiPayload,
  propertyId: string,
  token: string,
) => {
  console.log(form);
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/rs/v1/admin/properties/${propertyId}`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getAdminPropertyEndpoint = (propertyId: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/rs/v1/admin/properties/${propertyId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getAdminPropertyDetailEndpoint = (
  propertyId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/rs/v1/admin/properties/${propertyId}/detail`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getPhotosEndpoint = (propertyId: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/rs/v1/admin/properties/${propertyId}/gallery`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const uploadPhotosEndpoint = (
  form: any,
  propertyId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/rs/v1/admin/properties/${propertyId}/upload-images`,
        data: form,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const deleteGalleryPhotoEndpoint = (
  propertyId: string,
  imageUrl: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'delete',
        url: `${urlService}/rs/v1/admin/properties/${propertyId}/gallery`,
        data: JSON.stringify({ path: imageUrl }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

//delete all

export const deleteGalleryPropertyEndpoint = (
  propertyId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'delete',
        url: `${urlService}/rs/v1/admin/properties/${propertyId}/gallery-items`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

//sync
export const syncPropertyGalleryEndpoint = (
  propertyId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/rs/v1/admin/properties/${propertyId}/sync-gallery`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

//update gallery
export const uploadPropertyGalleryResourcesEndpoint = (
  propertyId: string,
  form: any,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/rs/v1/admin/properties/${propertyId}/gallery`,
        data: form,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

//sort gallery
export const sortPropertyGalleryEndpoint = (
  propertyId: string,
  images: string[],
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/rs/v1/admin/properties/${propertyId}/sort-gallery`,
        data: JSON.stringify({ paths: images }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getPropertyVisitsEndpoint = (
  propertyId: string,
  start: string,
  end: string,
  token: string = '',
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/properties/${propertyId}/visits?start=${start}&end=${end}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updatePropertyStatusEndpoint = (
  propertyId: string,
  form: PublishPropertyForm,
  token: string = '',
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/rs/v1/admin/properties/${propertyId}`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const createPropertyVisitsEndpoint = (
  form: CreateVisitFormApi,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/ts/v1/admin/visits`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updatePropertyVisitsEndpoint = (
  form: RescheduleFormApi,
  visitId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/ts/v1/admin/visits/${visitId}/reschedule`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getAvailableTimeVisitsEndpoint = (
  propertyId: string,
  day: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/visits/${propertyId}/available-time-visits/${day}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const uploadLinksPropertiesEndpoint = (
  form: UploadLinksForm,
  propertyId: string,
  token: string = '',
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/rs/v1/admin/properties/${propertyId}`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getLandlordPropertyOffers = (
  propertyId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/properties/${propertyId}/offers`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const takeDownPropertyEndpoint = (
  propertyId: string,
  payload: any,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/rs/v1/admin/properties/${propertyId}/hide`,
        data: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const deleteVisitEndpoint = (visitId: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'delete',
        url: `${urlService}/ts/v1/admin/visits/${visitId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const editProperVisitVisitEndpoint = (
  properId: string,
  visitId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/ts/v1/admin/visits/${visitId}/update-proper`,
        data: JSON.stringify({ proper_id: properId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getAdminPropertyViewCounterEndpoint = (
  propertyId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/rs/v1/admin/properties/${propertyId}/views`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updatePropertyIsVerifiedEndpoint = (
  form: PropertyUpdateIsVerifiedApi,
  propertyId: string,
  token: string,
) => {
  console.log(form);
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/rs/v1/admin/properties/${propertyId}`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
