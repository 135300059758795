import { useCallback, useEffect, useState } from 'react';
import { CommissionPreviewForm } from './models/commissionModel';
import { Col, Row } from 'reactstrap';
import AdminListGroup, {
  DisplayItem,
} from '../../components/admin/AdminListGroup';
import useCommissionDetail from './hooks/useCommissionDetail';
import AdminPage from '../../components/admin/AdminPage';
import CommissionForm from './components/CommissionForm';
import GetCanonValueForm from './components/GetCanonValueForm';

const PresalesCommissionCalculator = () => {
  const [errorAlert, setErrorAlert] = useState<string | null>(null);

  const { apiErrors, doCommissionDetailPreview } = useCommissionDetail();

  const handleForm = async (form: CommissionPreviewForm) => {
    try {
      const result = await doCommissionDetailPreview(form);
      const payload: DisplayItem[] = result.map((r) => {
        return {
          title: r.key,
          value: r.value,
        };
      });

      setPreviewCommission(payload);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (apiErrors) {
      console.error(apiErrors);
      setErrorAlert(apiErrors);
    }
  }, [apiErrors]);

  const [previewCommission, setPreviewCommission] = useState<DisplayItem[]>([]);


  return (
    <AdminPage
      name={'[Presales] Calculadora de comisiones'}
      parentName={'Comisiones'}
    >
      <Row>
        <Col md={6}>
          <Row>
            <Col md={12}>
              <CommissionForm
                handlePreview={handleForm}
                handleCreateOrUpdate={() => {
                }}
                isCalculatorMode={true}
                isPresalesMode={true}
              />
            </Col>

          </Row>
        </Col>
        <Col>
          <AdminListGroup
            title={`Detalle de distribución de ganancias`}
            items={previewCommission}
          />
        </Col>
      </Row>
    </AdminPage>
  );
};

export default PresalesCommissionCalculator;
