import axios from 'axios';
import { CreateAdminQuota, UpdateAdminQuota } from '../models/adminQuotas';

const loadAbort = () => {
  return new AbortController();
};

export const validFinancesPerMarketplaceEndpoint = (
  queryParams: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'GET',
        url: `${urlService}/ts/v1/admin/quotas/finances-per-marketplace?${queryParams}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const addQuotaEndpoint = (payload: CreateAdminQuota, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'POST',
        url: `${urlService}/ts/v1/admin/quotas`,
        data: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updateQuotaEndpoint = (
  id: string,
  payload: UpdateAdminQuota,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'PUT',
        url: `${urlService}/ts/v1/admin/quotas/${id}`,
        data: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
