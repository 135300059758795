import { Col, Container, Row } from 'reactstrap';
import AdminPage from '../../components/admin/AdminPage';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useCallback, useEffect, useState } from 'react';
import { ProjectChanged, ProjectsConfig } from './models/projectModel';
import ProjectCard from './components/ProjectCard';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { projectListByPositionEndpoint } from './services/projectService';
import useAuth from '../../hooks/useAuth';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import LoadingCard from '../../components/LoadingCard';
import { updateInvestorProjectEndpoint } from './services/investorService';

const ProjectConfigSort = () => {
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const pageName = 'Ordenamiento de proyectos';
  const pageParentName = 'Inversiones';

  const [projectsList, setProjectsList] = useState<ProjectsConfig[]>([]);

  const onDrag = (result: DropResult) => {
    const { source, destination } = result;

    // If dropped outside a droppable area
    if (!destination) return;

    // Skip if nothing changed
    if (source.index === destination.index) return;

    // Create a copy of projects to modify
    const updatedProjects = Array.from(projectsList);

    // Store the original positions before reordering
    const originalPositions = projectsList.map((project, index) => ({
      id: project.id,
      originalPosition: project.position || index + 1,
    }));

    // Perform the reordering
    const [reorderedProject] = updatedProjects.splice(source.index, 1);
    updatedProjects.splice(destination.index, 0, reorderedProject);

    // Update positions
    const reorderedWithPositions = updatedProjects.map((project, index) => ({
      ...project,
      position: index + 1,
    }));

    const changedProjects = reorderedWithPositions
      .filter((project, currentIndex) => {
        const originalInfo = originalPositions.find((p) => p.id === project.id);
        return (
          originalInfo && originalInfo.originalPosition !== project.position
        );
      })
      .map((project) => {
        const originalInfo = originalPositions.find((p) => p.id === project.id);
        return {
          ...project,
          originalPosition: originalInfo?.originalPosition,
          newPosition: project.position,
          positionChange:
            project.position - (originalInfo?.originalPosition || 0),
        };
      });

    // You can now use changedProjects for any additional processing
    // For example, API calls to update only the changed items

    if (changedProjects.length > 0)
      handleUpdateProjectPossition(changedProjects);

    //call update new position changed
    setProjectsList(reorderedWithPositions);
  };

  const getProjectLst = useCallback(async () => {
    const { status, data } = await callEndpoint(
      projectListByPositionEndpoint(token),
    );
    if (status === 200) {
      const response = fetchResponseAdapter(data);
      setProjectsList(response.data);
    }
  }, []);

  const handleChange = useCallback(
    async (projectId: string, change: boolean) => {
      const { status, data } = await callEndpoint(
        updateInvestorProjectEndpoint(
          projectId,
          {
            is_featured: change,
          },
          token,
        ),
      );
    },
    [],
  );

  const handleUpdateProjectPossition = useCallback(
    async (projectChanged: ProjectChanged[]) => {
      //promise all settle
      const changed = await Promise.allSettled(
        projectChanged.map(async (p) => {
          return await callEndpoint(
            updateInvestorProjectEndpoint(
              p.id,
              {
                position: p.newPosition,
              },
              token,
            ),
          );
        }),
      );
    },
    [],
  );

  useEffect(() => {
    getProjectLst().then().catch(console.error);
  }, [getProjectLst]);

  if (isLoading && projectsList.length === 0) {
    return <LoadingCard name={pageName} parent_name={pageParentName} />;
  }

  return (
    <AdminPage name={pageName} parentName={pageParentName}>
      <h2>Ordenamiento de proyectos de pre-venta</h2>

      <Container fluid>
        <Row>
          <Col xs="12">
            <DragDropContext onDragEnd={onDrag}>
              <Droppable droppableId="Proyectos">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      background: snapshot.isDraggingOver
                        ? '#f8f9fa'
                        : '#f1f3f5',
                      padding: '10px',
                      borderRadius: '4px',
                    }}
                  >
                    {projectsList.map((project, index) => (
                      <Draggable
                        key={project.id}
                        draggableId={project.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="mb-2"
                            style={{
                              ...provided.draggableProps.style,
                            }}
                          >
                            <ProjectCard
                              project={project}
                              isDragging={snapshot.isDragging}
                              handleChange={handleChange}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Col>
        </Row>
      </Container>
    </AdminPage>
  );
};

export default ProjectConfigSort;
