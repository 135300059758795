import React, { useCallback, useMemo, useState } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import { Button, Card, CardBody, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import {
  createModelUnitPaymentContractV2Endpoint,
  getModelUnitsEndpoint,
} from './services/investorService';
import useAuth from '../../hooks/useAuth';
import { useNavigate, useParams } from 'react-router-dom';
import AdminListGroup, { DisplayItem } from '../../components/admin/AdminListGroup';
import useAdminEntity from '../../hooks/useAdminEntity';
import { ModelUnitDetail } from './models/investors';
import { getLandlordByMailEndpoint } from '../../services/landlordService';
import { CommissionPreviewForm } from '../commissions/models/commissionModel';
import useCommissionDetail from '../commissions/hooks/useCommissionDetail';
import { checkOptions } from '../../constants/formConstants';
import Select from 'react-select';
import { listToSelectOptionAdapter } from '../../adapters/listAdapter';

const ModelUnitPaymentContract: React.FC = () => {
  const { id: investorProjectModelId = '', modelUnitId = '' } = useParams();
  const navigate = useNavigate();

  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { callEndpoint } = useCallApiAndLoad();

  const [errorAlert, setErrorAlert] = useState<any>(null);

  const getModelUnits = useCallback(
    () => getModelUnitsEndpoint(investorProjectModelId, token),
    [investorProjectModelId, token],
  );
  const [modelUnits] = useAdminEntity<ModelUnitDetail[]>(getModelUnits, setErrorAlert);

  const [landlordEmail, setLandlordEmail] = useState<string>('');
  const [landlord, setLandlord] = useState<any>();

  const currentModelUnit = useMemo(() => {
    return modelUnits && modelUnits.length > 0
      ? modelUnits.find((modelUnit) => modelUnit.id === modelUnitId)
      : { base_price: '', preview_dues: [], currency: '', commission_pct: 0.0 }
  }, [modelUnits]);

  const selectBrokerRegisterType = listToSelectOptionAdapter(
    [
      {
        label: 'No aplica',
        value: '',
      },
      {
        label: 'Broker registrado como sociedad (IVA)',
        value: 'broker_register_as_society',
      },
      {
        label: 'Broker registrado cómo persona natural (IVA + ISR)',
        value: 'broker_register_as_natural',
      },
      {
        label: 'Broker no registrado (ISR)',
        value: 'broker_no_register',
      },
    ],
    'value',
    'label',
  );
  const [previewCommission, setPreviewCommission] = useState<DisplayItem[]>([]);
  const [commissionCoupon, setCommissionCoupon] = useState<number>(0.0);
  const [commissionAdmonDue, setCommissionAdmonDue] = useState<number>(0.0);
  const [commissionHasIva, setCommissionHasIva] = useState<boolean>(false);
  const [commissionWithBroker, setCommissionWithBroker] =
    useState<boolean>(false);
  const [brokerRegisterType, setBrokerRegisterType] = useState<string>('');
  const { apiErrors, doCommissionDetailPreview } = useCommissionDetail();


  const commissionPreview = async () => {
    try {
      const basePrice = currentModelUnit ? currentModelUnit.base_price : '';
      const match= basePrice.match(/[0-9,\.]+/) || ['0.0'];
      const basePriceNumber= parseFloat(match[0].replace(/,/g, '') ); // replace , thousands separator
      const currency = currentModelUnit ? currentModelUnit.currency : '';
      const commissionPct = currentModelUnit ? currentModelUnit.commission_pct : 0.0;

      const payload: CommissionPreviewForm = {
        canon: (basePriceNumber * (commissionPct / 100.0)),
        admon_due: commissionAdmonDue,
        period: 12,
        coupon_percentage: commissionCoupon,
        is_broker_supply: false,
        commission_has_included_iva: commissionHasIva,
        commission_has_broker_involved: commissionWithBroker,
        currency: currency,
        broker_register_type: brokerRegisterType,
      };

      const result = await doCommissionDetailPreview(payload);
      const response: DisplayItem[] = result.map((r) => {
        return {
          title: r.key,
          value: r.value,
        };
      });

      setPreviewCommission(response);
    } catch (e) {
      console.error(e);
    }
  };

  const createPaymentContract = useCallback(async (landlordId: string, modelUnitId: string) => {
    const payload = {
      unit_id: modelUnitId,
      landlord_id: landlordId,
      // Commission Fields
      coupon_percentage: commissionCoupon / 100.0,
      commission_has_included_iva: commissionHasIva,
      commission_has_broker_involved: commissionWithBroker,
      admon_due: commissionAdmonDue,
      broker_register_type: brokerRegisterType,
      unit_commission_pct: currentModelUnit?.commission_pct,
    };
    const { status, data } = await callEndpoint(createModelUnitPaymentContractV2Endpoint(payload, token));
    const response = fetchResponseAdapter(data);
    if (status === 201) {
      navigate(`/investors/models/${investorProjectModelId}/model-units/`);
    } else {
      setErrorAlert(response.message);
    }
  }, [callEndpoint, token, commissionCoupon, commissionHasIva, commissionWithBroker, commissionAdmonDue, brokerRegisterType, currentModelUnit]);

  const handleSearchLandlord = useCallback(async (landlordEmailForSearch: string) => {
    if (!landlordEmailForSearch)
      return;

    const { status, data } = await callEndpoint(getLandlordByMailEndpoint(landlordEmailForSearch, token));
    if (status === 200) {
      const landlordResponse = fetchResponseAdapter(data);
      setLandlord(landlordResponse.data);
    } else {
      setErrorAlert('El inversionista no fue encontrado.');
    }

  }, [callEndpoint, createPaymentContract, modelUnitId]);

  const pageName = 'Generar mandato de pago';
  const pageParentName = 'Preventas';
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  const basePriceItem = [{ 'title': 'Precio base', 'value': currentModelUnit?.base_price }];
  const paymentContractItems = currentModelUnit?.preview_dues.map(item => ({
    'title': item.label,
    'value': item.value,
  })) || [];
  const commissionItems = [{title: 'Porcentaje de comisión (0 - 100%)', value: `${currentModelUnit?.commission_pct}%`}]

  return (
    <AdminPage name={pageName} parentName={pageParentName} globalAlertOptions={globalAlertOptions}>
      <Form role="form">
        <Row>
          <Col md="6">

            <Row>
              <Col>
                <AdminListGroup title={'Vista previa de cuotas'} items={basePriceItem.concat(paymentContractItems).concat(commissionItems)} />
              </Col>
            </Row>

            <Card>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <Form>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="admon_due_commission"
                        >
                          Cuota de administración
                        </label>
                        <Input
                          type="number"
                          id="commission_admin_due"
                          onChange={(event) => {
                            const commissionValue = parseFloat(event.target.value);
                            setCommissionAdmonDue(commissionValue);
                          }}
                          defaultValue={commissionAdmonDue}
                        />
                      </FormGroup>
                      <FormGroup>
                        <label className="form-control-label" htmlFor="coupon_commission">
                          Cupón de descuento (0% - 100%)
                        </label>
                        <Input
                          type="number"
                          id="commission_coupon"
                          min={0}
                          max={100}
                          minLength={0}
                          maxLength={3}
                          onChange={(event) => {
                            const commissionValue = parseFloat(event.target.value);
                            setCommissionCoupon(commissionValue);
                          }}
                          defaultValue={commissionCoupon}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Row>
                          <Col md={4}>
                            <label className="form-control-label" htmlFor="is_furnished">
                              IVA incluido
                            </label>
                          </Col>
                          <Col md={8}>
                            {checkOptions &&
                              checkOptions.map((item: any) => (
                                <div
                                  key={`has_iva-${item.name}`}
                                  className="custom-control custom-radio custom-control-inline"
                                >
                                  <input
                                    className="custom-control-input"
                                    type="radio"
                                    name="has_commission_iva"
                                    value={item.value}
                                    defaultChecked={
                                      item.value === (commissionHasIva || false)
                                    }
                                    id={`has_iva-${item.name}`}
                                    onChange={(event) => {
                                      const hasIva = event.currentTarget.value === 'true';
                                      setCommissionHasIva(hasIva);
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`has_iva-${item.name}`}
                                  >
                                    {item.name}
                                  </label>
                                </div>
                              ))}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <label
                              className="form-control-label"
                              htmlFor="commission_broker"
                            >
                              Comisión compartida (broker)
                            </label>
                          </Col>
                          <Col md={8}>
                            {checkOptions &&
                              checkOptions.map((item: any) => (
                                <div
                                  key={`has_broker-${item.name}`}
                                  className="custom-control custom-radio custom-control-inline"
                                >
                                  <input
                                    className="custom-control-input"
                                    type="radio"
                                    name="has_commission_broker"
                                    value={item.value}
                                    defaultChecked={
                                      item.value === (commissionWithBroker || false)
                                    }
                                    id={`has_broker-${item.name}`}
                                    onChange={(event) => {
                                      const hasIva = event.currentTarget.value === 'true';
                                      setCommissionWithBroker(hasIva);
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`has_broker-${item.name}`}
                                  >
                                    {item.name}
                                  </label>
                                </div>
                              ))}
                          </Col>
                        </Row>
                      </FormGroup>

                      <FormGroup>
                        <label
                          htmlFor="broker_register_type"
                          className="form-control-label"
                        >
                          Tipo de registro de broker
                        </label>
                        <Select
                          options={selectBrokerRegisterType}
                          placeholder="Selecciona tipo de broker"
                          onChange={(event) => {
                            setBrokerRegisterType(event?.value);
                          }}
                        />
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <label className="form-control-label"
                             htmlFor="plan_id">Asignar propietario</label>
                      <InputGroup className="mb-3">
                        <Input onChange={({ target }) => setLandlordEmail(target.value || '')}
                               placeholder="Correo de propietario" type="text" />
                        <InputGroupAddon addonType="append">
                          <Button
                            onClick={() => handleSearchLandlord(landlordEmail)}
                            color="primary" outline> Buscar
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                    {landlord && (<h5>Asignará la unidad a {landlord.name} | Teléfono: {landlord.phone}</h5>)}

                    <Button
                      size="md"
                      color="primary"
                      className="my-2"
                      disabled={!landlord}
                      onClick={async () => {
                        await createPaymentContract(landlord.landlord_id, modelUnitId);
                      }}>
                      Generar mandato de pago
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>


          </Col>
          <Col>
            <AdminListGroup
              title={`Detalle de distribución de ganancias [La propiedad es supply de PROPI]`}
              items={previewCommission}
              footer={
                <Button color={'info'} type="button" onClick={commissionPreview}>
                  Preview
                </Button>
              }
            />
          </Col>
        </Row>
      </Form>
    </AdminPage>
  );
};

export default ModelUnitPaymentContract;