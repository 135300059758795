import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import { TransactionPreSaleForm } from './models/investors';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from 'react';
import FormModal from '../../components/modals/FormModal';
import useModalState from '../../hooks/useModalState';
import useTransactionPreSale from './hooks/useTransactionPreSale';

interface Props {
  transactionPreSale: TransactionPreSaleForm;
  transactionPreSaleId: string;
}

const InvestorTransactionPreSaleBankingModal: React.FC<Props> = ({
  transactionPreSale,
  transactionPreSaleId,
}) => {
  const [isOpenModal, toggleModal] = useModalState();
  const { apiErrors, doUpdateTransactionPreSale } = useTransactionPreSale();
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm<TransactionPreSaleForm>({
    resolver: yupResolver(
      yup
        .object({
          is_cash_payment: yup.boolean().default(false),
          is_joint: yup.boolean().default(false),
        })
        .required(),
    ),
  });

  const handleForm = async (payload: TransactionPreSaleForm) => {
    try {
      doUpdateTransactionPreSale(transactionPreSaleId, payload);
    } catch (e) {}
  };

  const handleReset = () => {
    if (transactionPreSale) {
      reset({
        is_cash_payment: transactionPreSale.is_cash_payment,
        is_joint: transactionPreSale.is_joint,
      });
    }
  };

  useEffect(() => {
    handleReset();
  }, [transactionPreSale]);

  useEffect(() => {
    if (errors) console.log(errors);
  }, [errors]);

  //build form

  const formComponent = (
    <Form>
      <Row>
        <Col>
          <FormGroup>
            <Controller
              name="is_cash_payment"
              control={control}
              render={({ field: { onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="is_cash_payment"
                  type="checkbox"
                  className={errors.is_cash_payment?.message && 'is-invalid'}
                  style={{ marginLeft: 0 }}
                  onChange={(e) =>
                    setValue('is_cash_payment', e.target.checked)
                  }
                  defaultChecked={transactionPreSale?.is_cash_payment}
                />
              )}
            />

            <label
              htmlFor="is_cash_payment"
              className="form-control-label ml-3"
            >
              Es pago de contado
            </label>
            <h5 className="text-danger">{errors.is_cash_payment?.message}</h5>
          </FormGroup>
          <FormGroup>
            <Controller
              name="is_joint"
              control={control}
              render={({ field: { onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="is_joint"
                  type="checkbox"
                  className={errors.is_joint?.message && 'is-invalid'}
                  style={{ marginLeft: 0 }}
                  onChange={(e) => setValue('is_joint', e.target.checked)}
                  defaultChecked={transactionPreSale?.is_joint}
                  disabled={true}
                />
              )}
            />

            <label htmlFor="is_joint" className="form-control-label ml-3">
              Es pago mancomunado
            </label>
            <h5 className="text-danger">{errors.is_joint?.message}</h5>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );

  return (
    <>
      <Button
        color="info"
        onClick={() => {
          //toggle modal w data
          toggleModal();
        }}
      >
        Modificar datos
      </Button>
      <FormModal
        children={formComponent}
        headerText="Modificar datos"
        isOpen={isOpenModal}
        onConfirmation={handleSubmit(handleForm)}
        onReset={handleReset}
        onToggle={toggleModal}
        size="sm"
      />
    </>
  );
};

export default InvestorTransactionPreSaleBankingModal;
