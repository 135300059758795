import { Controller, useForm } from 'react-hook-form';
import { QuotaDetailForm } from './models/adminQuotas';
import { useEffect } from 'react';
import {
  QUOTA_TYPES,
  TransactionDetailState,
} from '../../constants/quotaConstants';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Select from 'react-select';
import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import { listToSelectOptionAdapter } from '../../adapters/listAdapter';
import { Option } from '../../models/formModel';

interface Props {
  currency: string;
  quota: QuotaDetailForm;
  marketplace_type: QUOTA_TYPES;
  related_id: string;
  belongs_quota_id?: string;
  is_create_mode: boolean;
  handleCreateOrUpdate: (form: QuotaDetailForm) => void;
  financesPerMarketplace: Option[];
}

const QuotaForm = ({
  currency,
  quota,
  marketplace_type,
  related_id,
  belongs_quota_id,
  is_create_mode = false,
  financesPerMarketplace = [],
  handleCreateOrUpdate,
}: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm<QuotaDetailForm>({
    resolver: yupResolver(
      yup.object({
        currency: yup.string().default(currency),
        price: yup.number().required('Requerido'),
        due: yup
          .string()
          .nullable()
          .when('is_create_mode', {
            is: true,
            then: (schema) => schema.required('Requerido'),
          }),
        due_date: yup
          .string()
          .nullable()
          .when('is_create_mode', {
            is: true,
            then: (schema) => schema.required('Requerido'),
          }),
        finance_id: yup
          .string()
          .nullable()
          .when('is_create_mode', {
            is: true,
            then: (schema) => schema.required('Requerido'),
          }),
        month: yup.string().nullable(),
        year: yup.string().nullable(),
        type: yup.string().default(marketplace_type),
        related_id: yup.string().default(related_id),
        status: yup.string().nullable(),
        belongs_quota_id: yup.string().default(belongs_quota_id),
        is_create_mode: yup.boolean().default(is_create_mode),
      }),
    ),
  });

  //avaialble status
  const avalableStatus = listToSelectOptionAdapter(
    [
      {
        label: 'Desactivar',
        value: TransactionDetailState.DISABLED,
      },
      {
        label: 'Sin paga',
        value: TransactionDetailState.CREATED,
      },
      {
        label: 'Cuota en curso',
        value: TransactionDetailState.CURRENT,
      },
    ],
    'value',
    'label',
  );

  useEffect(() => {
    if (quota) {
      reset({
        belongs_quota_id: belongs_quota_id,
        currency: quota.currency,
        due: quota.due,
        due_date: quota.due_date,
        finance_id: quota.finance_id,
        month: quota.month,
        price: quota.price,
        related_id: related_id,
        status: quota.status,
        type: marketplace_type,
        year: quota.year,
        is_create_mode: is_create_mode,
      });
    }
  }, [quota]);

  useEffect(() => {
    if (errors) console.log(errors);
  }, [errors]);

  //load finances

  return (
    <Form role="form" id="quotaForm">
      <Row>
        <Col lg="12">
          <FormGroup>
            <label className="form-control-label" htmlFor="price">
              Cuota
            </label>
            <Controller
              name="price"
              control={control}
              render={({ field }) => (
                <Input
                  className={errors.price?.message && 'is-invalid'}
                  {...field}
                  type="number"
                  placeholder="Ingresa el monto"
                  step="any"
                />
              )}
            />
            <h5 className="text-danger">{errors.price?.message}</h5>
          </FormGroup>
          {is_create_mode && (
            <>
              <FormGroup>
                <label className="form-control-label" htmlFor="finance_id">
                  Tipo de cuota
                </label>
                <Controller
                  name="finance_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={financesPerMarketplace}
                      placeholder="Selecciona tipo de cuota"
                      onChange={(event) => {
                        setValue('finance_id', event?.value);
                      }}
                    />
                  )}
                />
                <h5 className="text-danger">{errors.finance_id?.message}</h5>
              </FormGroup>
              <FormGroup>
                <label className="form-control-label" htmlFor="due">
                  Descripción (ej. 12/15)
                </label>
                <Controller
                  name="due"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className={errors.due?.message && 'is-invalid'}
                      {...field}
                      type="text"
                      placeholder="Ingresa la descripción"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.price?.message}</h5>
              </FormGroup>
              <FormGroup>
                <label className="form-control-label" htmlFor="due_date">
                  Fecha de pago
                </label>
                <Controller
                  name="due_date"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className={errors.due_date?.message && 'is-invalid'}
                      {...field}
                      type="date"
                      placeholder="Ingresa la fecha de pago"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.due_date?.message}</h5>
              </FormGroup>
            </>
          )}

          {!is_create_mode && (
            <FormGroup>
              <label className="form-control-label" htmlFor="status">
                Estado de la cuota
              </label>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Select
                    options={avalableStatus}
                    placeholder="Selecciona el estado de la cuota"
                    onChange={(event) => {
                      setValue('status', event?.value);
                    }}
                    defaultValue={avalableStatus.find(
                      (p) => p.value === quota?.status,
                    )}
                  />
                )}
              />
              <h5 className="text-danger">{errors.status?.message}</h5>
            </FormGroup>
          )}

          <Button
            color="info"
            onClick={() => {
              handleSubmit(handleCreateOrUpdate)();
            }}
          >
            {is_create_mode ? 'Crear cuota' : 'Actualizar cuota'}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default QuotaForm;
