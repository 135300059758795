import { Card, CardBody, ListGroup, Table } from 'reactstrap';
import { generateRandomString } from '../adapters/randomAdapter';
import { ReactElement } from 'react';

interface Props<T> {
  title: string;
  cardHeader: string[];
  rows: RowItem[];
  striped?: boolean;
  hover?: boolean;
}

export interface RowItem {
  specialRow?: boolean;
  coloredRow?: string;
  rows: string[] | ReactElement[];
}

const SimpleCardTable = ({
  cardHeader,
  rows,
  title,
  hover = false,
  striped = false,
}: Props<any>) => {
  return (
    <Card>
      <CardBody>
        <ListGroup className="list my--3" flush>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h6 className="heading-small text-muted mt-4 mb-4">{title}</h6>
          </div>
        </ListGroup>

        <div>
          <Table
            className="align-items-center table-flush hei"
            striped={striped}
            hover={hover}
          >
            <thead className="thead-light">
              <tr>
                {cardHeader.map((header) => (
                  <th key={header} scope="col">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.length === 0 && (
                <tr>
                  <td> No disponible</td>
                </tr>
              )}
              {rows.map((item) => (
                <tr
                  key={generateRandomString(6)}
                  className={item.specialRow ? item.coloredRow : ''}
                >
                  {item.rows.map((element) => (
                    <td key={generateRandomString(6)}>{element}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

export default SimpleCardTable;
