import React from 'react';
import AdminListGroup from '../../../components/admin/AdminListGroup';
import { InvestorTransaction } from '../models/investors';
import { Badge } from 'reactstrap';

export type Props = {
  transactionDetails: InvestorTransaction;
  footer: any;
};

const InvestorTransactionDetailCard: React.FC<Props> = ({
  transactionDetails,
  footer,
}) => {
  const customBadgeIsCashPayment = (
    <Badge
      className="badge-sm"
      color={transactionDetails.is_cash_payment ? 'success' : 'info'}
    >
      {transactionDetails.is_cash_payment
        ? 'Es pago de contado'
        : 'Pago normal'}
    </Badge>
  );

  const customBadgeIsJointPayment = (
    <Badge
      className="badge-sm"
      color={transactionDetails.is_joint ? 'success' : 'info'}
    >
      {transactionDetails.is_joint ? 'Es mancomunidado' : 'Inversionista'}
    </Badge>
  );

  const fieldMapper = [
    {
      title: 'Código de proyecto',
      value: transactionDetails.code,
    },
    {
      title: 'Proyecto',
      value: transactionDetails.name,
    },
    {
      title: 'Modelo',
      value: transactionDetails.model_name,
    },
    {
      title: 'Unidad',
      value: transactionDetails.unit_name,
    },
    {
      title: 'Inversionista',
      value: `${transactionDetails.landlord_name} ${transactionDetails.landlord_lastname}`,
    },
    {
      title: 'Contacto',
      value: `${transactionDetails.landlord_email} [${transactionDetails.landlord_phone}]`,
    },
    {
      title: 'Precio',
      value: transactionDetails.unit_price,
    },
    {
      title: 'Información adicional',
      value: (
        <>
          {customBadgeIsCashPayment}
          {customBadgeIsJointPayment}
        </>
      ),
    },
  ];

  return (
    <AdminListGroup
      title="Detalles del transacción"
      items={fieldMapper}
      footer={footer}
    />
  );
};

export default InvestorTransactionDetailCard;
