import React, { useState } from 'react';
import { SyncMarketplaceResourcesEnum } from '../models/SyncMarketplaceModel';
import { useForm } from 'react-hook-form';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import {
  syncAvailableUnitsByProjectEndpoint,
  syncMarketplacePreSalesEndpoint,
  syncMarketplacePropertyEndpoint,
} from '../services/syncMarketplaceService';
import useAuth from '../../../hooks/useAuth';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

interface Props {
  item_id: string;
  item_type: string;
}

export const useSyncMarketplaceForm = ({ item_id, item_type }: Props) => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const [jsonResponse, setJsonResponse] = useState<object | null>(null);
  const { callEndpoint, isLoading } = useCallApiAndLoad();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    resource: SyncMarketplaceResourcesEnum;
  }>({
    resolver: yupResolver(
      yup
        .object({
          resource: yup
            .string()
            .required('Identificador de oferta es requerido'),
        })
        .required(),
    ),
  });

  const onSubmit = async (data: { resource: SyncMarketplaceResourcesEnum }) => {
    if (data.resource === SyncMarketplaceResourcesEnum.PROPERTY) {
      const { data: responseData } = await callEndpoint(
        syncMarketplacePropertyEndpoint(token, item_id),
      );
      setJsonResponse(fetchResponseAdapter(responseData));
    } else {
      // It is pre sales
      if (
        data.resource ===
        SyncMarketplaceResourcesEnum.AVAILABLE_UNITS_BY_PROJECT
      ) {
        const { data: responseData } = await callEndpoint(
          syncAvailableUnitsByProjectEndpoint(token, item_id),
        );
        setJsonResponse(fetchResponseAdapter(responseData));
      } else {
        const { data: responseData } = await callEndpoint(
          syncMarketplacePreSalesEndpoint(token, data.resource, item_id),
        );
        setJsonResponse(fetchResponseAdapter(responseData));
      }
    }
  };

  return {
    jsonResponse,
    control,
    handleSubmit,
    onSubmit,
    isLoading,
    errors,
  };
};
