import { useParams, useNavigate } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Progress,
  Row,
} from 'reactstrap';
import LoadingCard from '../../components/LoadingCard';
import PropertyDetailCard from '../../components/PropertyDetailCard';
import { useAdminProperty } from './hooks/useAdminProperty';
import HeaderCounters from './components/AdminProperty/HeaderCounters';
import LandlordVerifiedCard from './components/AdminProperty/LandlordVerifiedCard';
import usePropertyCounterViews from './hooks/usePropertyCounterViews';
import {
  getPropertySaleStatus,
  getPropertyStatus,
} from './adapters/propertyAdapter';
import useAuth from '../../hooks/useAuth';

const AdminProperty = () => {
  const { id = '' } = useParams();
  const { isPermissionAvailable } = useAuth();
  const getProgressBarColor = (percentage: number) => {
    if (percentage > 60) {
      return 'success';
    } else if (percentage > 40) {
      return 'warning';
    } else {
      return 'danger';
    }
  };
  const navigate = useNavigate();
  const { isLoading, adminPropertyData, changeIsVerified } =
    useAdminProperty(id);
  const { isLoading: isLoadingCounters, lastCounter } =
    usePropertyCounterViews(id);

  if (isLoading || !adminPropertyData) {
    return <LoadingCard name="" parent_name="" />;
  }

  return (
    <>
      <HeaderCounters
        property_id={id}
        parentName="Propiedades"
        name={`Administrar propiedad #${adminPropertyData.code}`}
        offer_count={adminPropertyData.offers}
        visit_count={adminPropertyData.visits}
        visualization_text={lastCounter.text}
        visualization_count={lastCounter.counter}
      />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <Row>
              <Col md="6">
                <PropertyDetailCard
                  cover={adminPropertyData.cover}
                  address={adminPropertyData.address}
                  code={adminPropertyData.code}
                  price={adminPropertyData.monthly_price}
                  sale_price={adminPropertyData.sale_monthly_price}
                />
              </Col>
              <Col md="6">
                <LandlordVerifiedCard
                  is_validated_by_proper={
                    adminPropertyData.is_validated_by_proper ?? false
                  }
                  validated_by_proper_event={changeIsVerified}
                  landlord_name={adminPropertyData.landlordInfo?.name ?? ''}
                  landlord_id={adminPropertyData.landlord_id ?? ''}
                  plan={adminPropertyData.plan ?? ''}
                  property_id={id}
                />
              </Col>
            </Row>
          </CardHeader>
          <CardHeader className="py-0">
            <div className="progress-wrapper">
              <div className="progress-info">
                <div className="progress-label">
                  <span>Información de propiedad</span>
                </div>
                <div className="progress-percentage">
                  <span>{adminPropertyData.admin_data_progress}%</span>
                </div>
              </div>
              <Progress
                max="100"
                value={adminPropertyData.admin_data_progress}
                color={getProgressBarColor(
                  adminPropertyData.admin_data_progress,
                )}
              />
            </div>
          </CardHeader>
          <CardBody>
            <hr />
            <ListGroup className="list my--3" flush>
              <ListGroupItem>
                <Row className="align-items-center">
                  <div className="col ml--2">
                    <h4 className="mb-0">Información Adicional</h4>
                    <small>
                      Propietario, Proper, Nombre de propiedad, descripción
                    </small>
                  </div>
                  <Col className="col-auto">
                    <Button
                      onClick={() =>
                        navigate(`/properties/admin/${id}/additional-info`)
                      }
                      color=""
                      size="sm"
                      type="button"
                    >
                      <i className="ni ni-bold-right pt-1" />
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>

            <hr />
            <ListGroup className="list my--3" flush>
              <ListGroupItem>
                <Row className="align-items-center">
                  <div className="col ml--2">
                    <h4 className="mb-0">Dirección y ubicación</h4>
                    <small>
                      Ubicación GPS y asignación de polígono/residencial/torre
                    </small>
                  </div>
                  <Col className="col-auto">
                    <Button
                      onClick={() =>
                        navigate(`/properties/admin/${id}/address-location`)
                      }
                      color=""
                      size="sm"
                      type="button"
                    >
                      <i className="ni ni-bold-right pt-1" />
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>

            <hr />
            <ListGroup className="list my--3" flush>
              <ListGroupItem>
                <Row className="align-items-center">
                  <div className="col ml--2">
                    <h4 className="mb-0">Amenidades y características</h4>
                    <small>
                      Habitaciones, baños, área, liean blanca, amueblado,
                      mascotas
                    </small>
                  </div>
                  <Col className="col-auto">
                    <Button
                      onClick={() =>
                        navigate(`/properties/admin/${id}/amenities`)
                      }
                      color=""
                      size="sm"
                      type="button"
                    >
                      <i className="ni ni-bold-right pt-1" />
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>

            <hr />
            <ListGroup className="list my--3" flush>
              <ListGroupItem>
                <Row className="align-items-center">
                  <div className="col ml--2">
                    <h4 className="mb-0">Configurar información de Renta</h4>
                    <small>
                      Precios de renta, estados en marketplace de rentas
                    </small>
                  </div>
                  <Col className="col-auto">
                    <Badge color="default">
                      {getPropertyStatus(adminPropertyData.status ?? '')}
                    </Badge>
                    <Button
                      onClick={() => navigate(`/properties/admin/${id}/rent`)}
                      color=""
                      size="sm"
                      type="button"
                    >
                      <i className="ni ni-bold-right pt-1" />
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>

            <hr />
            <ListGroup className="list my--3" flush>
              <ListGroupItem>
                <Row className="align-items-center">
                  <div className="col ml--2">
                    <h4 className="mb-0">Configurar información de Venta</h4>
                    <small>
                      Precios de venta, estados en marketplace de ventas,
                      información de escrituración, carta extractada
                    </small>
                  </div>
                  <Col className="col-auto">
                    <Badge color="default">
                      {getPropertySaleStatus(
                        adminPropertyData.sale_status ?? '',
                      )}
                    </Badge>
                    <Button
                      onClick={() => navigate(`/properties/admin/${id}/sales`)}
                      color=""
                      size="sm"
                      type="button"
                    >
                      <i className="ni ni-bold-right pt-1" />
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>

            <hr />
            <ListGroup className="list my--3" flush>
              <ListGroupItem>
                <Row className="align-items-center">
                  <div className="col ml--2">
                    <h4 className="mb-0">
                      Administrar contenido y fotografías
                    </h4>
                    <small>Cover, galería, matterport, link de youtube</small>
                  </div>
                  <Col className="col-auto">
                    <Button
                      onClick={() => navigate(`/properties/admin/${id}/photos`)}
                      color=""
                      size="sm"
                      type="button"
                    >
                      <i className="ni ni-bold-right pt-1" />
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
            <hr />
            <ListGroup className="list my--3" flush>
              <ListGroupItem>
                <Row className="align-items-center">
                  <div className="col ml--2">
                    <h4 className="mb-0">Configurar agente inmobiliario</h4>
                  </div>
                  <Col className="col-auto">
                    <Button
                      onClick={() => navigate(`/properties/admin/${id}/broker`)}
                      color=""
                      size="sm"
                      type="button"
                    >
                      <i className="ni ni-bold-right pt-1" />
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
            <hr />
            {isPermissionAvailable('SHOW_MARKETPLACE_SYNC') && (
              <>
                <ListGroup className="list my--3" flush>
                  <ListGroupItem>
                    <Row className="align-items-center">
                      <div className="col ml--2">
                        <h4 className="mb-0">Sincronizar con marketplace</h4>
                      </div>
                      <Col className="col-auto">
                        <Button
                          onClick={() =>
                            navigate(`/sync-marketplace/${id}/property`)
                          }
                          color=""
                          size="sm"
                          type="button"
                        >
                          <i className="ni ni-bold-right pt-1" />
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
                <hr />
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default AdminProperty;
