import { Alert, Button, Col, Form, FormGroup, Modal, Row } from 'reactstrap';
import useDownPaymentQuotas from '../hooks/useDownPaymentQuotas';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import InvestorDownPaymentQuotsPreview from './InvestorDownPaymentQuotasPreview';

interface Props {
  headerText: string;
  isOpen: boolean;
  onToggle: () => void;
  transactionPreSaleId: string | undefined;
  transactionDetailId: string | undefined;
  max_quotas?: number;
  finance_text: string;
  finance_type: string;
}

export default function InvestorDownPaymentQuotasModal({
  isOpen,
  onToggle,
  headerText,
  transactionPreSaleId,
  transactionDetailId,
  max_quotas = -1,
  finance_text,
  finance_type,
}: Props) {
  const {
    availableQuotas,
    downPaymentPreviewDues,
    apiErrors,
    control,
    errors,
    isLoading,
    setValue,
    handleSubmit,
    doCreateDownPaymentQuotas,
    doPreviewDownPaymentQuotas,
    resetValues,
  } = useDownPaymentQuotas({
    transactionDetailId: transactionDetailId ?? '',
    transactionPreSaleId: transactionPreSaleId ?? '',
    max_quotas: max_quotas,
    finance_type: finance_type,
  });

  const onReset = () => {
    resetValues();
    onToggle();
  };

  return (
    <Modal
      size="lg"
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => onReset()}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          {headerText}
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onReset()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        {apiErrors && (
          <Alert color="danger">
            <strong>Error!</strong> {apiErrors}
          </Alert>
        )}
        <Form id="downPaymentQuota" role="form">
          <Row>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label" htmlFor="quotas">
                  Cantidad de cuotas a elegir
                </label>
                <Controller
                  name="quotas"
                  control={control}
                  render={({ field: { ref } }) => (
                    <Select
                      placeholder="Selecciona quotas"
                      options={availableQuotas}
                      onChange={(selected: any) => {
                        const { value = '' } = selected;
                        setValue('quotas', value);
                      }}
                      ref={ref}
                    />
                  )}
                />
                <h5 className="text-danger">{errors.quotas?.message}</h5>
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <InvestorDownPaymentQuotsPreview
          financeText={finance_text}
          previewDues={downPaymentPreviewDues}
        />
      </div>
      <div className="modal-footer">
        <Button
          form="downPaymentQuota"
          color={'success'}
          onClick={handleSubmit(doCreateDownPaymentQuotas)}
          disabled={isLoading}
        >
          {headerText}
        </Button>

        <Button
          form="downPaymentQuota"
          color={'warning'}
          onClick={handleSubmit(doPreviewDownPaymentQuotas)}
          disabled={isLoading}
        >
          Verificar plan de cuotas
        </Button>
      </div>
    </Modal>
  );
}
