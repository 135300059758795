import { useState } from 'react';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import useAuth from '../../../hooks/useAuth';
import { QUOTA_TYPES } from '../../../constants/quotaConstants';
import {
  CreateAdminQuota,
  UpdateAdminQuota,
  ValidFinancesPerMarketplace,
} from '../models/adminQuotas';
import {
  addQuotaEndpoint,
  updateQuotaEndpoint,
  validFinancesPerMarketplaceEndpoint,
} from '../services/adminQuotasService';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import { createSearchParams, useNavigate } from 'react-router-dom';

const useAdminQuotas = () => {
  const navigate = useNavigate();
  const { callEndpoint } = useCallApiAndLoad();
  const [apiErrors, setApiErrors] = useState<string | null>(null);

  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  //actions

  const doRetrieveValidFinancesPerMarketplace = async (
    type: QUOTA_TYPES,
  ): Promise<ValidFinancesPerMarketplace[]> => {
    const { data } = await callEndpoint(
      validFinancesPerMarketplaceEndpoint(
        createSearchParams({
          type: type,
        }).toString(),
        token,
      ),
    );

    const response = fetchResponseAdapter(data);

    return response.data;
  };

  const doAddQuota = async (payload: CreateAdminQuota) => {
    const { data, status } = await callEndpoint(
      addQuotaEndpoint(payload, token),
    );
    const response = fetchResponseAdapter(data);

    if (status === 201) {
      //success
      navigate(0);
    } else {
      //error
      setApiErrors(response.message);
    }
  };

  const doUpdateQuota = async (id: string, payload: UpdateAdminQuota) => {
    const { data, status } = await callEndpoint(
      updateQuotaEndpoint(id, payload, token),
    );
    const response = fetchResponseAdapter(data);

    if (status === 200) {
      //success
      navigate(0);
    } else {
      //error
      setApiErrors(response.message);
    }
  };

  return {
    apiErrors,
    doRetrieveValidFinancesPerMarketplace,
    doAddQuota,
    doUpdateQuota,
  };
};

export default useAdminQuotas;
