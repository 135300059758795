import { Card, CardBody, Col, Row } from 'reactstrap';
import './sass/style.scss';
import { useParams, useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import {
  deleteGalleryPhotoEndpoint,
  deleteGalleryPropertyEndpoint,
  getPropertyToEditEndpoint,
  sortPropertyGalleryEndpoint,
  syncPropertyGalleryEndpoint,
  uploadLinksPropertiesEndpoint,
  uploadPhotosEndpoint,
  uploadPropertyGalleryResourcesEndpoint,
} from './services/propertyService';
import { PropertyDetail, UploadLinksForm } from './models/propertyModel';
import LoadingCard from '../../components/LoadingCard';
import useAuth from '../../hooks/useAuth';
import AdminPage from '../../components/admin/AdminPage';
import AdminUploadResource from '../../components/admin/AdminUploadResource';
import AdminResourceGallery from '../../components/admin/AdminResourceGallery';
import useAdminEntity from '../../hooks/useAdminEntity';
import { wait } from '../../helpers/commonHelpers';
import PropertyVideoLinksForm from './PropertyVideoLinksForm';

const PropertyPhotos = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const [errorAlert, setErrorAlert] = useState<any>(null);

  const pageName = `Recursos de la propiedad`;
  const pageParentName = `Propiedad`;
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  const getPropertyDetails = useCallback(
    () => getPropertyToEditEndpoint(id, token),
    [id, token],
  );

  const [property] = useAdminEntity<PropertyDetail>(
    getPropertyDetails,
    setErrorAlert,
  );

  const deleteImage = async (imagePath: string) => {
    if (!imagePath) {
      return;
    }
    const { status } = await callEndpoint(
      deleteGalleryPhotoEndpoint(id, imagePath, token),
    );
    if (status === 200) {
      navigate(0);
    } else {
      setErrorAlert(
        'Lo sentimos, por el momento no es posible completar la acción',
      );
    }
  };

  const doDeleteAllImagesFromGallery = useCallback(async () => {
    const { status } = await callEndpoint(
      deleteGalleryPropertyEndpoint(id, token),
    );
    if (status === 200) {
      navigate(0); //make a reload
    } else {
      setErrorAlert(
        'Lo sentimos, por el momento no es posible completar la acción',
      );
    }
  }, [id, token, callEndpoint, navigate]);

  const doSaveLinksProperties = async (form: UploadLinksForm) => {
    const { status } = await callEndpoint(
      uploadLinksPropertiesEndpoint(form, id, token),
    );
    if (status === 200) navigate(0); //make a reload
    else
      setErrorAlert(
        'Lo sentimos, por el momento no es posible completar la acción',
      );
  };

  const uploadPropertyCover = useCallback(
    async (files: Array<any>) => {
      if (files.length < 0) return;

      const formData = new FormData();
      files.forEach((file) => formData.append('cover', file));

      const { status } = await callEndpoint(
        uploadPhotosEndpoint(
          formData,
          id,

          token,
        ),
      );
      if (status === 200) navigate(0);
    },
    [id, token, callEndpoint, navigate],
  );

  const uploadPropertyGallery = useCallback(
    async (files: Array<any>) => {
      //make n calls
      await Promise.allSettled(
        files.map((f) => {
          const formData = new FormData();
          formData.append('gallery', f);

          return callEndpoint(
            uploadPropertyGalleryResourcesEndpoint(id, formData, token),
          );
        }),
      );

      wait(2000)
        .then(async (r) => {
          //sync
          await doSyncPropertyGallery();
        })
        .catch();
    },
    [id, token, callEndpoint],
  );

  const doSyncPropertyGallery = useCallback(async () => {
    await callEndpoint(syncPropertyGalleryEndpoint(id, token));
    //reload
    navigate(0);
  }, [id, callEndpoint, navigate, token]);

  const doSortPropertyGallery = useCallback(
    async (images: string[]) => {
      const { status } = await callEndpoint(
        sortPropertyGalleryEndpoint(id, images, token),
      );
      if (status !== 200) {
        console.log('error update');
      } else {
        navigate(0);
      }
    },
    [id, callEndpoint, navigate, token],
  );

  if (!property || isLoading)
    return <LoadingCard name={pageName} parent_name={pageParentName} />;

  const coverGallery = [property.cover].filter((img) => img !== null);
  const propertyGallery = [...property.gallery].filter((img) => img !== null);

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
    >
      <Row>
        <Col>
          <Card>
            <CardBody>
              <PropertyVideoLinksForm
                handleForm={doSaveLinksProperties}
                property={property}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <AdminUploadResource
            handleUpload={uploadPropertyCover}
            description="Subir cover"
            maxFiles={1}
          />
        </Col>
        <Col>
          <AdminResourceGallery
            gallery={coverGallery}
            description={'Cover actual'}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <AdminUploadResource
            handleUpload={uploadPropertyGallery}
            description="Subir galeria"
          />
        </Col>
        <Col>
          <AdminResourceGallery
            gallery={propertyGallery}
            description={'Galeria actual'}
            onDelete={deleteImage}
            onDeleteAll={doDeleteAllImagesFromGallery}
            onSync={doSyncPropertyGallery}
            onDrag={doSortPropertyGallery}
          />
        </Col>
      </Row>
    </AdminPage>
  );
};

export default PropertyPhotos;
