export type QUOTA_TYPES = 'rent' | 'pre_sale' | 'sale';

export enum TransactionDetailState {
  CREATED = 'created',
  CURRENT = 'current',
  PAID = 'paid',
  PENDING = 'pending', // or overdue
  REVIEW = 'review', // used for transfer pay to review in admin
  DISABLED = 'disabled',
  ANULATE = 'anulate',
}
