import axios from 'axios';
import { SyncMarketplaceResourcesEnum } from '../models/SyncMarketplaceModel';
const loadAbort = () => {
  return new AbortController();
};
// TODO move this services to a admin token
export const syncMarketplacePropertyEndpoint = (
  token: string,
  propertyId: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/rs/v1/internal-sync-marketplace/properties/migrate/${propertyId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const syncMarketplacePreSalesEndpoint = (
  token: string,
  resource: SyncMarketplaceResourcesEnum,
  resource_id: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/pre-sales/v1/internal-sync-marketplace/projects/migrate/${resource}/${resource_id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const syncAvailableUnitsByProjectEndpoint = (
  token: string,
  project_id: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/marketplace/v1/internal/sync/available-units/${project_id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
