import { PaymentsDetailsTable } from '../../../components/payments/PaymentsDetailsTable';
import { InvestorTransactionDetail } from '../models/investors';
import { getTransactionDetailStatusAdapter } from '../../../adapters/transactionAdapter';
import { QUOTA_TYPES } from '../../../constants/quotaConstants';

interface Props {
  transactionId: string;
  currency: string;
  type: QUOTA_TYPES;
  items: InvestorTransactionDetail[];
  duesPaids?: boolean;
}

const InvestFuturePayments = ({
  items,
  transactionId,
  type = 'rent',
  currency = 'USD',
  duesPaids = false,
}: Props) => {
  const itemsMapped = items.map((item) => ({
    id: item.id,
    title: item.label,
    value: item.price,
    status: getTransactionDetailStatusAdapter(item.status),
    customDot: item.status === 'paid' ? 'bg-success' : '',
    href: `/investors/payments/${transactionId}/quota/${item.id}`,
    allow_divide_quota: item.allow_divide_quotas,
    max_quotas: item.max_quotas,
    finance_type: item.finance_type,
    status_aux: item.status,
  }));
  return (
    <PaymentsDetailsTable
      transactionId={transactionId}
      title="Detalle de cuotas"
      items={itemsMapped}
      type={type}
      dues_paids={duesPaids}
      currency={currency}
    />
  );
};

export default InvestFuturePayments;
