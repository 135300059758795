import { Button } from 'reactstrap';
import BasicModal from '../../../components/modals/BasicModal';
import SimpleCardTable, { RowItem } from '../../../components/SimpleCardTable';
import useModalState from '../../../hooks/useModalState';
import { InvestorJoint } from '../models/investors';
import useInvestorJoint from '../hooks/useInvestorJoint';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  joints: InvestorJoint[];
}

const InvestorJoinTransactionPreSaleCard: React.FC<Props> = ({ joints }) => {
  const { apiErrors, doDeleteJointPreSale } = useInvestorJoint();
  const [jointId, setJointId] = useState<string | null>(null);
  const navigate = useNavigate();

  const headers: string[] = ['Nombre', 'Contacto', 'Actiones'];
  const rows = joints.map((j) => {
    return {
      rows: [
        `${j.customer.firstname} ${j.customer.lastname}`,
        `${j.customer.email}`,
        <Button
          className="btn-icon btn-3"
          color="danger"
          type="button"
          size="sm"
          onClick={() => {
            toggleModal();
            setJointId(j.id);
          }}
        >
          <span className="btn-inner--icon">
            <i className="ni ni-fat-remove" />
          </span>
          <span className="btn-inner--text">Eliminar</span>
        </Button>,
      ],
    } as RowItem;
  });
  const [isOpenModal, toggleModal] = useModalState();

  const confirmation = async () => {
    if (jointId) {
      try {
        const result = await doDeleteJointPreSale(jointId);
        if (result === 200) navigate(0);
        else {
          //handle error
          console.log(apiErrors);
        }
      } catch (e) {}
    }
  };
  return (
    <>
      <SimpleCardTable
        cardHeader={headers}
        rows={rows}
        title="Información de mancomunados"
      />
      <BasicModal
        abortText="Seguir revisando"
        bodyText="Deseas eliminar el inversionista asignado"
        onConfirmation={confirmation}
        isOpen={isOpenModal}
        onToggle={toggleModal}
        titleText="Eliminar mancomunado"
        confirmationText="Continuar"
      />
    </>
  );
};

export default InvestorJoinTransactionPreSaleCard;
