import { useNavigate, useParams } from 'react-router-dom';
import AdminPage from '../../components/admin/AdminPage';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { useCallback, useState } from 'react';
import {
  getTransactionSalesPaymentDetailEndpoint,
  unselectTransactionSalesEndpoint,
} from './services/transactionSaleService';
import useAdminEntity from '../../hooks/useAdminEntity';
import { RetrieveTransactionSaleDetail } from './models/transactionSaleModel';
import LoadingCard from '../../components/LoadingCard';
import { Button, Col, Row } from 'reactstrap';
import TransactionSaleInfo from './components/TransactionSaleInfo';
import TransactionSaleInfoPayments from './components/TransactionSaleInfoPayments';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import BasicModal from '../../components/modals/BasicModal';
import useModalState from '../../hooks/useModalState';

const TransactionSalePaymentDetail = () => {
  const { id: transactionSaleId = '' } = useParams();
  const { getAuthToken } = useAuth();
  const navigate = useNavigate();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [errorAlert, setErrorAlert] = useState(null);
  const pageName = 'Detalle de pago';
  const pageParentName = 'Listado de compras vivienda usada';

  const getTransactionDetail = useCallback(
    () => getTransactionSalesPaymentDetailEndpoint(transactionSaleId, token),
    [transactionSaleId, token],
  );

  const disableSale = useCallback(async (transactionId: string) => {
    const { status, data } = await callEndpoint(
      unselectTransactionSalesEndpoint(transactionId, token),
    );
    if (status === 200) {
      navigate(`/transaction-sales/${transactionSaleId}`);
    } else {
      const response = fetchResponseAdapter(data);
      setErrorAlert(response.message);
    }
    toggleDisableTransactionModal();
  }, []);

  const [transaction] = useAdminEntity<RetrieveTransactionSaleDetail>(
    getTransactionDetail,
    setErrorAlert,
  );
  const [showDisableTransaction, toggleDisableTransactionModal] =
    useModalState();

  if (!transaction || isLoading)
    return <LoadingCard name={pageName} parent_name={pageParentName} />;

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      parentUri={'/transaction-sales'}
    >
      <Row>
        <Col lg="4">
          <TransactionSaleInfo
            transactionSaleDetail={transaction.transaction}
            footer={
              <>
                {transaction.transaction_detail_paid.length <= 0 && (
                  <Button
                    color="danger"
                    onClick={() => toggleDisableTransactionModal()}
                  >
                    Anular reserva
                  </Button>
                )}
              </>
            }
          />
        </Col>
        <Col lg="8">
          <Row>
            <Col lg="12">
              <TransactionSaleInfoPayments
                transactionSaleId={transactionSaleId}
                transactionItems={transaction.transaction_detail}
                label="Detalle de cuotas por pagar"
                currency="" //TODO: send currency here
              />
            </Col>
            <Col lg="12">
              <TransactionSaleInfoPayments
                transactionSaleId={transactionSaleId}
                transactionItems={transaction.transaction_detail_paid}
                label="Detalle de cuotas pagadas"
                currency="" //TODO: send currency here
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <BasicModal
        abortText={'No, seguir revisando...'}
        bodyText={
          'Al anular la reserva se vuelve a colocar la unidad como disponible en el marketplace.'
        }
        confirmationText={'Entiendo, anular'}
        isOpen={showDisableTransaction}
        onConfirmation={disableSale}
        onToggle={toggleDisableTransactionModal}
        titleText={'Anular reserva'}
        payload={{ transactionId: transactionSaleId }}
      />
    </AdminPage>
  );
};

export default TransactionSalePaymentDetail;
