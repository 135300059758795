import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { useState } from 'react';
import { CreateJointPreSale } from '../models/jointInvestor';
import {
  addJointPreSalesEndpoint,
  deleteJointPreSalesEndpoint,
} from '../services/jointService';
import useAuth from '../../../hooks/useAuth';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';

const useInvestorJoint = () => {
  const { callEndpoint } = useCallApiAndLoad();
  const [apiErrors, setApiErrors] = useState<string | null>(null);

  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const doAddJointPreSale = async (
    payload: CreateJointPreSale,
  ): Promise<number> => {
    const { status, data } = await callEndpoint(
      addJointPreSalesEndpoint(payload, token),
    );
    const response = fetchResponseAdapter(data);

    if (status !== 201) setApiErrors(response.message);
    return status;
  };

  const doDeleteJointPreSale = async (jointId: string): Promise<number> => {
    const { status, data } = await callEndpoint(
      deleteJointPreSalesEndpoint(jointId, token),
    );
    const response = fetchResponseAdapter(data);

    if (status !== 200) setApiErrors(response.message);
    return status;
  };

  return {
    apiErrors,
    doAddJointPreSale,
    doDeleteJointPreSale,
  };
};

export default useInvestorJoint;
