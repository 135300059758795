import { useState } from 'react';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { TransactionPreSaleForm } from '../models/investors';
import useAuth from '../../../hooks/useAuth';
import { updateTransactionPreSaleEndpoint } from '../services/transactionPreSaleService';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import { useNavigate } from 'react-router-dom';

const useTransactionPreSale = () => {
  const { callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const [apiErrors, setApiErrors] = useState<string | null>(null);

  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const doUpdateTransactionPreSale = async (
    id: string,
    form: TransactionPreSaleForm,
  ): Promise<void> => {
    const { status, data } = await callEndpoint(
      updateTransactionPreSaleEndpoint(id, form, token),
    );
    const response = fetchResponseAdapter(data);

    if (status === 200) navigate(0);
    else setApiErrors(response.message);
  };

  return {
    apiErrors,
    doUpdateTransactionPreSale,
  };
};

export default useTransactionPreSale;
