import { Form, FormGroup } from 'reactstrap';
import useAdminEntity from '../../../hooks/useAdminEntity';
import { getProjectListEndpoint } from '../../withdrawal/services/projectService';
import useAuth from '../../../hooks/useAuth';
import { useCallback, useMemo, useState } from 'react';
import Select from 'react-select';
import { listToSelectOptionAdapter } from '../../../adapters/listAdapter';
import {
  getInvestorProjectDetailEndpoint,
  getModelUnitsEndpoint,
  getProjectModelsEndpoint,
} from '../../investors/services/investorService';

interface EntitySummary {
  id: number;
  name: string;
}

interface ProjectEntitySummary extends EntitySummary {
  commission_pct: number;
  currency: string;
}

interface ModelUnitEntitySummary extends EntitySummary {
  base_price: string;
}

export interface Props {
  onUnitSelected: Function
}

const GetCanonValueForm: React.FC<Props> = ({onUnitSelected}) => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const [projectId, setProjectId] = useState<string | null>(null);
  const [projectModelId, setProjectModelId] = useState<string | null>(null);

  const getPresalesProjectList = useCallback(() => {
    return getProjectListEndpoint(token)
  }, [token]);
  const getPresaleProjectDetail = useCallback(() => {
    return getInvestorProjectDetailEndpoint(projectId || '', token)
  }, [projectId])
  const getPresalesProjectModelList = useCallback(() => {
    return getProjectModelsEndpoint(projectId || '', token)
  }, [projectId, token]);
  const getPresalesProjectModelUnitList = useCallback(() => {
    return getModelUnitsEndpoint(projectModelId || '', token)
  }, [projectModelId, token]);

  const errors = useCallback((e:  string) => console.error(e), []);

  const [projectList] = useAdminEntity<EntitySummary[]>(getPresalesProjectList, errors);
  const [projectDetail] = useAdminEntity<ProjectEntitySummary>(getPresaleProjectDetail, errors);
  const [projectModelList] = useAdminEntity<EntitySummary[]>(getPresalesProjectModelList, errors);
  const [projectModelUnitList] = useAdminEntity<ModelUnitEntitySummary[]>(getPresalesProjectModelUnitList, errors);
  const loadingOptions = [{
    id: '',
    name: 'Cargando opciones',
    disabled: true,
  }]
  const projectOptions = useMemo(
    () => listToSelectOptionAdapter(projectList || loadingOptions, 'id', 'name'),
    [projectList]
  );
  const projectModelOptions = useMemo(
    () => listToSelectOptionAdapter(projectModelList || loadingOptions, 'id', 'name'),
    [projectModelList]
  );
  const projectModelUnitOptions = useMemo(
    () => listToSelectOptionAdapter(projectModelUnitList || loadingOptions, 'id', 'name'),
    [projectModelUnitList]
  );

  return (
    <Form role="form">
      <FormGroup>
        <label className="form-control-label" htmlFor="currency">
          Proyectos
        </label>
        <Select
          options={projectOptions}
          placeholder="Selecciona un proyecto"
          onChange={(event) => {
            setProjectId(event?.value);
          }}
        />
      </FormGroup>
      <FormGroup>
        <label className="form-control-label" htmlFor="currency">
          Modelos
        </label>
        <Select
          options={projectModelOptions}
          placeholder="Selecciona un modelo"
          onChange={(event) => {
            setProjectModelId(event?.value);
          }}
        />
      </FormGroup>
      <FormGroup>
        <label className="form-control-label" htmlFor="currency">
          Unidades
        </label>
        <Select
          options={projectModelUnitOptions}
          placeholder="Selecciona una unidad"
          onChange={(event) => {
            const unitId = event?.value
            const basePrice = projectModelUnitList.find(unit => unit.id === unitId)?.base_price || '';
            const match= basePrice.match(/[0-9,\.]+/) || ['0.0'];
            const amount= parseFloat(match[0].replace(/,/g, '') ); // replace , thousands separator
            const commissionPct = projectDetail? projectDetail.commission_pct : 0.0;
            const currency = projectDetail ? projectDetail.currency : '';

            onUnitSelected(unitId, amount, commissionPct, currency);
          }}
        />
      </FormGroup>
    </Form>
  );
};


export default GetCanonValueForm;