import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  CardHeader,
  Card,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
  NavLink,
  Col,
} from 'reactstrap';
import { useState, useEffect, useCallback } from 'react';

import TimelineHeader from '../../components/headers/TimelineHeader';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { getPropertiesEndpoint } from './services/propertyService';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import { PropertiesList } from './models/propertyModel';
import { Paginator } from '../../models/formModel';
import {
  getOptionPropertyStatus,
  getPropertySaleStatus,
  getPropertyStatus,
} from './adapters/propertyAdapter';
import useAuth from '../../hooks/useAuth';
import GridSearchBar from '../../components/searchBar/GridSearchBar';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../redux/store';
import { setGridFiltersAction } from '../../redux/features/gridSearchBar';
import LoadingCard from '../../components/LoadingCard';
import { generatePagination } from '../../adapters/paginateListAdapter';
import { useNavigate } from 'react-router-dom';
import {
  getLandlordByEmailEndpoint,
  getLandlordByNameEndpoint,
} from '../accounting/services/accountingService';
import {
  getCitiesForSearchEndpoint,
  getStatesForSearchEndpoint,
} from '../../services/publicService';
import { DEFAULT_AVATAR } from '../../constants/profileConstants';
import PropertyMobileListing from './components/PropertyMobileListing';
import useUserCountry from '../../hooks/useUserCountry';

const Properties = () => {
  const { getAuthToken, isPermissionAvailable } = useAuth();
  const token = getAuthToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [properties, setProperties] = useState<PropertiesList[]>([]);
  const [statesOptions, setStatesOptions] = useState<any[]>([]);
  const [citiesOptions, setCitiesOptions] = useState<any[]>([]);
  const [paginator, setPaginator] = useState<Paginator>({
    page: 0,
    take: 0,
    itemCount: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });
  const [paginationList, setPaginationList] = useState(Array<number>);
  const gridSearchBar = useSelector((store: AppStore) => store.gridSearchBar);
  const { getCurrentCountry } = useUserCountry();
  const currentUserCountryCode =
    getCurrentCountry()?.code.toLowerCase() ?? 'sv';

  const loadProperties = async () => {
    const { status, data } = await callEndpoint(
      getPropertiesEndpoint(gridSearchBar.query, token),
    );
    if (status === 200) {
      const response = fetchResponseAdapter(data);
      setProperties(response.data.items || []);
      setPaginator(response.data.meta);
      setPaginationList(
        generatePagination(
          response.data.meta.page,
          response.data.meta.pageCount,
          5,
        ),
      );
    }
  };

  const loadStatesOptions = useCallback(async () => {
    const { status, data } = await callEndpoint(getStatesForSearchEndpoint());
    if (status === 200) {
      const response = fetchResponseAdapter(data);
      setStatesOptions(
        response.data.map((state: any) => ({
          value: state.state_id,
          label: state.state_name,
        })),
      );
    }
  }, [callEndpoint]);

  const loadCitiesOptions = useCallback(async () => {
    const { status, data } = await callEndpoint(getCitiesForSearchEndpoint());
    if (status === 200) {
      const response = fetchResponseAdapter(data);
      setCitiesOptions(
        response.data.map((city: any) => ({
          value: city.city_id,
          label: city.city_name,
        })),
      );
    }
  }, [callEndpoint]);

  useEffect(() => {
    loadProperties().catch((e) => console.error(e));

    loadStatesOptions().catch((e) => console.error(e));

    loadCitiesOptions().catch((e) => console.error(e));
  }, [gridSearchBar]);

  if (!properties && !statesOptions && !citiesOptions && isLoading) {
    return (
      <LoadingCard name={'Lista de propiedades'} parent_name={'Propiedades'} />
    );
  }

  const propertyFilters = [
    {
      label: 'Código de propiedad',
      value: 'code',
      apiParamName: 'code',
      inputType: 'number',
      isUnique: true,
      placeholder: 'Ingresa el código de propiedad',
    },
    {
      label: 'Estado de propiedad',
      value: 'status',
      apiParamName: 'status',
      inputType: 'select',
      inputTypeValues: getOptionPropertyStatus(),
      isUnique: false,
      placeholder: 'Seleccione...',
    },
    {
      label: 'Nombre propietario',
      value: 'landlord_name',
      apiParamName: 'landlord_id',
      inputType: 'search',
      isUnique: true,
      placeholder: 'Ingrese el nombre del propietario',
      inputTypeChoices: async (searchTerm: string) => {
        if (!searchTerm) return [];

        const { status, data } = await callEndpoint(
          getLandlordByNameEndpoint(searchTerm, token),
        );

        if (status === 200) {
          const response = fetchResponseAdapter(data);
          return response.data.map((landlord: any) => ({
            value: landlord.landlord_id,
            label: (
              <>
                <span>{landlord.name}</span>
                <small className="text-gray mx-2">{landlord.phone}</small>
              </>
            ),
          }));
        } else {
          return [];
        }
      },
    },
    {
      label: 'Email propietario',
      value: 'landlord_email',
      apiParamName: 'landlord_id',
      inputType: 'search',
      isUnique: true,
      placeholder: 'Ingrese el email del propietario',
      inputTypeChoices: async (searchTerm: string) => {
        if (!searchTerm) return [];

        const { status, data } = await callEndpoint(
          getLandlordByEmailEndpoint(searchTerm, token),
        );

        if (status === 200) {
          const response = fetchResponseAdapter(data);
          return response.data.map((landlord: any) => ({
            value: landlord.landlord_id,
            label: (
              <>
                <span>{landlord.name}</span>
                <small className="text-gray mx-2">{landlord.phone}</small>
              </>
            ),
          }));
        } else {
          return [];
        }
      },
    },
  ];

  const mobileViewComponent = (
    <Row className="mx-2 d-lg-none">
      {properties.length > 0 &&
        properties.map((property) => (
          <Col md="4" sm="6">
            <PropertyMobileListing property={property} />
          </Col>
        ))}
    </Row>
  );

  const desktopViewComponent = (
    <Table
      className="align-items-center table-flush d-none d-lg-block"
      responsive
    >
      <thead className="thead-light">
        <tr>
          <th scope="col">Propiedad</th>
          <th scope="col">ID</th>
          <th scope="col">Dirección</th>
          <th scope="col">Propietario</th>
          <th scope="col">Nombre del propietario</th>
          <th scope="col">Estado de renta</th>
          <th scope="col">Cuota de renta</th>
          <th scope="col">Ofertas</th>
          <th scope="col">Estado de venta</th>
          <th scope="col">Precio de venta</th>
          <th scope="col" />
          <th scope="col" />
        </tr>
      </thead>
      <tbody>
        {properties.length > 0 &&
          properties.map((property) => (
            <tr key={property.id}>
              <th scope="row">
                <Col>
                  <img
                    alt="..."
                    width={175}
                    height={100}
                    src={
                      property.cover && !property.cover.endsWith('null')
                        ? property.cover
                        : 'https://storage.googleapis.com/assets-us-east4-propilatam-dev/properties/default/default_1.jpg'
                    }
                  />
                </Col>
                <Col>
                  <span>{property.admin_data_progress}% completado</span>
                </Col>
              </th>
              <td>
                <a
                  key={`a-code-${property.id}`}
                  href={`/properties/admin/${property.id}`}
                  target="_blank"
                >
                  {property.code}
                </a>
              </td>
              <td>{property.address}</td>
              <td>
                <img
                  className="avatar avatar-sm rounded-circle"
                  src={
                    property.landlordInfo && property.landlordInfo.avatar
                      ? property.landlordInfo.avatar
                      : DEFAULT_AVATAR
                  }
                />
              </td>
              <td>
                {property.landlordInfo && property.landlordInfo.name
                  ? property.landlordInfo.name
                  : ''}
              </td>
              <td>{getPropertyStatus(property.status)}</td>
              <td>{property.monthly_price}</td>
              <td>{property.offers}</td>
              <td>{getPropertySaleStatus(property.sale_status)}</td>
              <td>{property.sale_monthly_price}</td>
              <td>
                {property.url_sale_detail &&
                  property.status === 'published' && (
                    <NavLink
                      href={`${process.env.REACT_APP_PROPI_WEB}/${currentUserCountryCode}/alquiler/${property.url_detail}/${property.code}`}
                      target="_blank"
                    >
                      <Button
                        className="btn-icon btn-3"
                        size="sm"
                        color="info"
                        type="button"
                      >
                        <span className="btn-inner--icon">
                          <i className="ni ni-bag-17" />
                        </span>
                        <span className="btn-inner--text">Renta</span>
                      </Button>
                    </NavLink>
                  )}
                {property.url_sale_detail &&
                  property.sale_status === 'published' && (
                    <NavLink
                      href={`${process.env.REACT_APP_PROPI_WEB}/${currentUserCountryCode}/venta/${property.url_sale_detail}/${property.code}`}
                      target="_blank"
                    >
                      <Button
                        className="btn-icon btn-3"
                        color="primary"
                        type="button"
                        size="sm"
                      >
                        <span className="btn-inner--icon">
                          <i className="ni ni-bag-17" />
                        </span>
                        <span className="btn-inner--text">Venta</span>
                      </Button>
                    </NavLink>
                  )}
              </td>
              <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    href="#"
                    role="button"
                    size="sm"
                    color=""
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem href={`/properties/admin/${property.id}`}>
                      Ver detalle
                    </DropdownItem>
                    <DropdownItem
                      href={`/properties/admin/${property.id}/rent`}
                    >
                      Configurar información de renta
                    </DropdownItem>
                    <DropdownItem
                      href={`/properties/admin/${property.id}/sales`}
                    >
                      Configurar información de venta
                    </DropdownItem>
                    {isPermissionAvailable('SHOW_MARKETPLACE_SYNC') && (
                      <DropdownItem
                        href={`sync-marketplace/${property.id}/property`}
                      >
                        Sincronizar con marketplace
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );

  return (
    <>
      <TimelineHeader name="Lista de propiedades" parentName="Propiedades" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <GridSearchBar filtersList={propertyFilters} />
              </CardHeader>
              {desktopViewComponent}
              {mobileViewComponent}
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem
                      className={!paginator.hasPreviousPage ? 'disabled' : ''}
                    >
                      <PaginationLink
                        onClick={() => {
                          dispatch(
                            setGridFiltersAction({
                              page: paginator.page - 1,
                              take: paginator.take,
                            }),
                          );
                        }}
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Anterior</span>
                      </PaginationLink>
                    </PaginationItem>
                    {paginationList.map((page) => (
                      <PaginationItem
                        key={page}
                        className={paginator.page === page ? 'active' : ''}
                      >
                        <PaginationLink
                          onClick={() => {
                            dispatch(
                              setGridFiltersAction({
                                page: page,
                                take: paginator.take,
                              }),
                            );
                          }}
                        >
                          {page}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem
                      className={!paginator.hasNextPage ? 'disabled' : ''}
                    >
                      <PaginationLink
                        onClick={() => {
                          dispatch(
                            setGridFiltersAction({
                              page: paginator.page + 1,
                              take: paginator.take,
                            }),
                          );
                        }}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Siguiente</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Properties;
