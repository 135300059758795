import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminPage from '../../components/admin/AdminPage';
import { Controller, useForm } from 'react-hook-form';
import { Form, FormGroup, Label, Input, Button, Row, Col } from 'reactstrap';
import { useSyncMarketplaceForm } from './hooks/useSyncMarketplaceForm';
import { SyncMarketplaceResourcesEnum } from './models/SyncMarketplaceModel';

export const SyncMarketplaceForm = () => {
  const { item_id = '', item_type = '' } = useParams();
  const { onSubmit, handleSubmit, control, jsonResponse, isLoading, errors } =
    useSyncMarketplaceForm({ item_id, item_type });
  const name = 'Configuración para sincronizar marketplace';
  const parentName = 'Sincronizar marketplace';

  // Filter options based on item_type
  const options =
    item_type === 'property'
      ? { PROPERTY: SyncMarketplaceResourcesEnum.PROPERTY }
      : item_type === 'model'
      ? { MODEL: SyncMarketplaceResourcesEnum.MODEL }
      : item_type === 'unit'
      ? { UNIT: SyncMarketplaceResourcesEnum.UNIT }
      : {
          PROJECT: SyncMarketplaceResourcesEnum.PROJECT,
          UNITS_BY_PROJECT: SyncMarketplaceResourcesEnum.UNITS_BY_PROJECT,
          MODELS_BY_PROJECT: SyncMarketplaceResourcesEnum.MODELS_BY_PROJECT,
          AVAILABLE_UNITS_BY_PROJECT:
            SyncMarketplaceResourcesEnum.AVAILABLE_UNITS_BY_PROJECT,
        };

  return (
    <AdminPage name={name} parentName={parentName}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          {/* Primera columna: Select */}
          <Col md="6">
            <FormGroup>
              <Label for="resource">Selecciona un recurso:</Label>
              <Controller
                name="resource"
                control={control}
                render={({ field }) => (
                  <Input
                    className={errors.resource?.message && 'is-invalid'}
                    type="select"
                    {...field}
                    id="resource"
                  >
                    <option key={'resource-null'} value={''}>
                      Seleccione un recurso
                    </option>
                    {Object.entries(options).map(([key, value]) => (
                      <option key={key} value={value}>
                        {value}
                      </option>
                    ))}
                  </Input>
                )}
              />
              <h5 className="text-danger">{errors.resource?.message}</h5>
            </FormGroup>
            <Button color="primary" type="submit" disabled={isLoading}>
              Sincronizar
            </Button>
          </Col>

          {/* Segunda columna: Visor de JSON */}
          <Col md="6">
            <FormGroup>
              <Label for="jsonViewer">Respuesta de sincronizador:</Label>
              <Input
                type="textarea"
                id="jsonViewer"
                value={
                  jsonResponse ? JSON.stringify(jsonResponse, null, 2) : ''
                }
                readOnly
                style={{
                  height: '300px',
                  backgroundColor: '#2d2d2d',
                  color: '#f8f8f2',
                  fontFamily: 'monospace',
                  fontSize: '14px',
                  lineHeight: '1.5',
                  padding: '10px',
                  border: '1px solid #444',
                  borderRadius: '4px',
                  overflow: 'auto',
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </AdminPage>
  );
};
