import React, { useCallback, useEffect, useState } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import { Col, Row } from 'reactstrap';
import ModelUnitSummaryCard from './components/ModelUnitSummaryCard';
import { getModelUnitsEndpoint } from './services/investorService';
import { useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useAdminEntity from '../../hooks/useAdminEntity';
import { ModelUnitDetail } from './models/investors';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import LoadingCard from '../../components/LoadingCard';
import ModelUnitListFilters from './components/ModelUnitListFilters';

const ModelUnitList: React.FC = () => {
  const { id: projectModelId = '' } = useParams();
  const [errorAlert, setErrorAlert] = useState(null);

  const { isLoading } = useCallApiAndLoad();

  const { getAuthToken, isPermissionAvailable } = useAuth();
  const token = getAuthToken();
  const isValidSyncMarketplace = isPermissionAvailable('SHOW_MARKETPLACE_SYNC');

  const pageName = `Unidades`;
  const pageParentName = `Preventa`;
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  const getModelUnits = useCallback(
    () => getModelUnitsEndpoint(projectModelId, token),
    [projectModelId, token],
  );
  const [modelUnits] = useAdminEntity<ModelUnitDetail[]>(
    getModelUnits,
    setErrorAlert,
  );

  const [modelUnitsForDisplay, setModelUnitsForDisplay] = useState<
    ModelUnitDetail[]
  >([]);
  const filterModelUnits = useCallback(
    (allModelUnits: ModelUnitDetail[], filters: any) => {
      return allModelUnits.filter((modelUnit) => {
        let cond = true;
        if (filters?.modelUnitName)
          cond =
            cond &&
            (modelUnit.name
              .toLowerCase()
              .includes(filters?.modelUnitName.toLowerCase()) ||
              modelUnit.investor_name
                .toLowerCase()
                .includes(filters?.modelUnitName.toLowerCase()));

        if (filters?.modelUnitStatus)
          cond =
            cond &&
            modelUnit.status
              .toLowerCase()
              .includes(filters?.modelUnitStatus.toLowerCase());

        return cond;
      });
    },
    [],
  );

  useEffect(() => {
    if (modelUnits && modelUnits.length > 0) {
      const unitsForDisplay = filterModelUnits(modelUnits, {});
      setModelUnitsForDisplay(unitsForDisplay);
    }
  }, [modelUnits]);

  if (!modelUnits || isLoading)
    return <LoadingCard name={pageName} parent_name={pageParentName} />;

  const componentHeader = (
    <>
      <ModelUnitListFilters
        onChange={(filters: any) => {
          const unitsForDisplay = filterModelUnits(modelUnits, filters);
          setModelUnitsForDisplay(unitsForDisplay);
        }}
      />
    </>
  );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
      header={componentHeader}
    >
      <Row>
        {modelUnitsForDisplay.map((modelUnit) => (
          <Col key={modelUnit.id} md="4">
            <ModelUnitSummaryCard
              projectModelId={projectModelId}
              modelUnit={modelUnit}
              isValidSyncMarketplace={isValidSyncMarketplace}
            />
          </Col>
        ))}
      </Row>
    </AdminPage>
  );
};

export default ModelUnitList;
