import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import useModalState from '../../../hooks/useModalState';
import FormModal from '../../../components/modals/FormModal';
import LandlordSearchInput from '../../../components/landlordInvestors/LandlordSearchInput';
import useInvestorJoint from '../hooks/useInvestorJoint';
import { useNavigate } from 'react-router-dom';

interface Props {
  transaction_pre_sale_id: string;
}

const InvestorAddJointTransactionPreSaleButton = ({
  transaction_pre_sale_id,
}: Props) => {
  const navigate = useNavigate();
  const [landlordId, setLandlordId] = useState<string>();
  const [isOpenModal, toggleModal] = useModalState();

  const { doAddJointPreSale, apiErrors } = useInvestorJoint();

  const handleSearchLandlord = (id: string) => {
    setLandlordId(id);
  };

  const handleReset = () => {
    setLandlordId(undefined);
  };

  const handleSubmit = async () => {
    //call use
    if (landlordId) {
      const result = await doAddJointPreSale({
        landlord_id: landlordId,
        transaction_pre_sale_id: transaction_pre_sale_id,
      });
      if (result === 201) navigate(0);
      else {
        //handle error
        console.log(apiErrors);
      }
    }
  };

  const formComponent = (
    <LandlordSearchInput
      header="Buscar inversionista"
      onLandlordfound={handleSearchLandlord}
    />
  );

  return (
    <>
      <Button
        color="success"
        type="button"
        onClick={() => {
          toggleModal();
        }}
      >
        Agregar mancomunado
      </Button>
      <FormModal
        children={formComponent}
        headerText="Buscar mancomunado"
        isOpen={isOpenModal}
        onConfirmation={handleSubmit}
        onReset={handleReset}
        onToggle={toggleModal}
        size="lg"
      />
    </>
  );
};

export default InvestorAddJointTransactionPreSaleButton;
