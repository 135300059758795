import { getTransactionDetailStatusAdapter } from '../../../adapters/transactionAdapter';
import { PaymentsDetailsTable } from '../../../components/payments/PaymentsDetailsTable';
import { PAYMENT_METHODS } from '../../../constants/transactionConstants';
import { InvestorTransactionDetail } from '../models/investors';

interface Props {
  transactionId: string;
  items: InvestorTransactionDetail[];
}

const InvestPaidPayments = ({ items, transactionId }: Props) => {
  const itemsMapped = items.map((item) => ({
    id: item.id,
    title: item.label,
    value: item.price,
    status: getTransactionDetailStatusAdapter(item.status),
    customDot: item.status === 'paid' ? 'bg-success' : '',
    href: `/investors/payments/${transactionId}/quota/${item.id}`,
    payment_method: PAYMENT_METHODS[item.payment_method],
    has_proof_income: item.has_proof_income,
    status_aux: item.status,
  }));
  return (
    <PaymentsDetailsTable
      title="Detalle de cuotas pagadas"
      items={itemsMapped}
      dues_paids={true}
      currency=""
    />
  );
};

export default InvestPaidPayments;
