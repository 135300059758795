import { useEffect, useState } from 'react';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { PropertyDetail, UploadLinksForm } from './models/propertyModel';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  UncontrolledAlert,
} from 'reactstrap';

interface Props {
  handleForm: (form: UploadLinksForm) => void;
  property: PropertyDetail;
}

const PropertyVideoLinksForm = ({ handleForm, property }: Props) => {
  const { isLoading } = useCallApiAndLoad();
  const [error, setError] = useState(null);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<UploadLinksForm>({
    resolver: yupResolver(
      yup
        .object({
          link_tour360: yup
            .string()
            .nullable()
            .transform((value) => (value === '' ? null : value)),
          link_video: yup
            .string()
            .nullable()
            .transform((value) => (value === '' ? null : value)),
        })
        .required(),
    ),
  });

  useEffect(() => {
    if (property) {
      reset({
        link_tour360: property.link_tour360,
        link_video: property.link_video,
      });
    }
  }, [property]);

  return (
    <Form role="form" onSubmit={handleSubmit(handleForm)}>
      <Row>
        <Col md="6">
          <Row>
            <Col>
              {error && (
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--text">{error}</span>
                </UncontrolledAlert>
              )}

              <FormGroup>
                <label className="form-control-label" htmlFor="link_tour360">
                  Link tour 360
                </label>
                <Controller
                  name="link_tour360"
                  control={control}
                  defaultValue={property?.link_tour360 || ''}
                  key={`${property?.link_tour360}-link_tour360-key`}
                  render={({ field }) => (
                    <Input
                      className={errors.link_tour360?.message && 'is-invalid'}
                      {...field}
                      type="text"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.link_tour360?.message}</h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="link_video">
                  Link video
                </label>
                <Controller
                  name="link_video"
                  control={control}
                  defaultValue={property?.link_video || ''}
                  key={`${property?.link_video}-link_video-key`}
                  render={({ field }) => (
                    <Input
                      className={errors.link_video?.message && 'is-invalid'}
                      {...field}
                      type="text"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.link_video?.message}</h5>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>

      <Button color="primary" disabled={isLoading}>
        Actualizar
      </Button>
    </Form>
  );
};

export default PropertyVideoLinksForm;
