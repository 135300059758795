import React from 'react';
import { TransactionDetailItem } from '../models/contracts';
import { PaymentsDetailsTable } from '../../../components/payments/PaymentsDetailsTable';
import { getTransactionDetailStatusAdapter } from '../../../adapters/transactionAdapter';

export type ContractFuturePaymentsProps = {
  transactionId: string;
  transactionItems: Array<TransactionDetailItem>;
  duesPaids?: boolean;
  currency: string;
};

const ContractFuturePayments: React.FC<ContractFuturePaymentsProps> = ({
  transactionId,
  transactionItems,
  currency = 'USD',
  duesPaids = false,
}) => {
  const itemsMapped = transactionItems.map((item) => ({
    id: item.id,
    title: item.label,
    value: item.price,
    status: getTransactionDetailStatusAdapter(item.status),
    customDot: item.status === 'paid' ? 'bg-success' : '',
    href: `/contracts/${item.contractId}/quota/${item.id}`,
    due_month: item.due_month,
    status_aux: item.status,
  }));
  return (
    <PaymentsDetailsTable
      transactionId={transactionId}
      title="Detalle de cuotas por pagar"
      items={itemsMapped}
      currency={currency}
      dues_paids={duesPaids}
      show_due_months={true}
      allow_update_quota={true}
      allow_create_quota={true}
    />
  );
};

export default ContractFuturePayments;
