import AdminListGroup, {
  DisplayItem,
} from '../../../components/admin/AdminListGroup';
import {
  IncomeProductType,
  TransactionDetailObject,
} from '../models/proofIncomeModel';

export interface Props {
  transactionDetail: TransactionDetailObject;
  income_product: IncomeProductType;
}
const AccountingProofIncomeTransactionDetailRef: React.FC<Props> = ({
  transactionDetail,
  income_product,
}) => {
  const itemList: DisplayItem[] = [];

  let href: string = '';

  if (income_product === 'pre_sale')
    href = `/investors/payments/${transactionDetail.parentId}`;
  if (income_product === 'rent')
    href = `/contracts/${transactionDetail.parentId}/payment-status`;
  if (income_product === 'sale')
    href = `/transaction-sales/${transactionDetail.parentId}`;

  if (transactionDetail) {
    itemList.push({
      title: `${transactionDetail.description}`,
      value: `${transactionDetail.value}`,
      key: transactionDetail.id,
      href: href,
    });
  }

  return (
    <AdminListGroup
      title={'Detalle de transacciones relacionada'}
      items={itemList}
    />
  );
};

export default AccountingProofIncomeTransactionDetailRef;
